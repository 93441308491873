import {
  Button,
  Checkbox,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UserContext } from '../context/UserStateManager';
import { TimeConditionItem } from '../devices/DeviceSettingsObject';

type props = {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  disabled: boolean[];
  current?: TimeConditionItem;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: '20vh auto',
      width: 'fit-content',
    },
    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    form: {
      height: '200px',
      padding: '80px min(calc((100vw - 322px) / 2), 80px)',
      maxWidth: '500px',
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
    },
    CheckBox: {
      margin: '-4px',
    },
    DayCheck: {
      height: '36px',
      width: '36px',
      borderRadius: '36px',
      fontSize: '18px',
      fontWeight: 600,
      '& > *': {
        paddingTop: '6px',
        display: 'inline-block',
      },
    },
    Checked: {
      backgroundColor: '#0380BA',
      color: '#F4F7FC',
      'input:disabled ~ &': {
        backgroundColor: '#aaaaaa',
      },
    },
    UnChecked: {
      backgroundColor: '#F2F2F3',
      color: '#171725',
      'input:disabled ~ &': {
        backgroundColor: '#aaaaaa',
      },
    },
  }),
);

const defaultVals: TimeConditionItem = {
  startTime: '0800',
  endTime: '1800',
  daysOfWeek: Array(7).fill(false),
};

export default function TimeSelectModal({
  open,
  onClose,
  onSubmit,
  disabled,
  current,
}: props) {
  const classes = useStyles();

  const userCon = useContext(UserContext);
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm<TimeConditionItem>({ defaultValues: defaultVals });

  useEffect(() => {
    reset(current || defaultVals);
  }, [current, reset]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        clearErrors();
      }}
    >
      <Paper className={classes.paper}>
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit((value) => {
              let non_selected = true;
              for (let bool of value.daysOfWeek) {
                if (bool) non_selected = false;
              }
              if (non_selected) {
                setError('daysOfWeek', { message: 'Select at least one day.' });
                return;
              }
              onSubmit({
                ...value,
              });
              setValue('startTime', defaultVals.startTime);
              setValue('endTime', defaultVals.endTime);
              setValue('daysOfWeek', defaultVals.daysOfWeek);
            })(e);
          }}
        >
          <Typography variant="h3">
            Times are in controller's timezone
            {userCon.state.currentDevice
              ? `: ${moment
                  .tz(userCon.state.currentDevice.timeZone)
                  .zoneAbbr()}`
              : '.'}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Controller
              name="startTime"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  label="On Time"
                  style={{ width: '80px' }}
                  value={moment(value, 'HHmm')}
                  onChange={(time) =>
                    onChange((time as moment.Moment).format('HHmm'))
                  }
                />
              )}
            />
            <Controller
              name="endTime"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TimePicker
                  label="Off Time"
                  style={{ width: '80px' }}
                  value={moment(value, 'HHmm')}
                  onChange={(time) =>
                    onChange((time as moment.Moment).format('HHmm'))
                  }
                />
              )}
              // rules={{
              //   validate: (val) =>
              //     moment(val).isAfter(moment(getValues('startTime'))) ||
              //     'End Time must be after Start Time.',
              // }}
            />
          </div>
          <Controller
            name="daysOfWeek"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                {[
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ].map((day, i) => (
                  <Checkbox
                    key={i}
                    aria-label={'checkbox-' + day}
                    className={classes.CheckBox}
                    icon={
                      <div
                        className={classes.UnChecked + ' ' + classes.DayCheck}
                      >
                        <span>{day.slice(0, 1)}</span>
                      </div>
                    }
                    checkedIcon={
                      <div className={classes.Checked + ' ' + classes.DayCheck}>
                        <span>{day.slice(0, 1)}</span>
                      </div>
                    }
                    value={i}
                    checked={value[i]}
                    onChange={(e) => {
                      let ar = [...value];
                      ar[i] = e.target.checked;
                      onChange(ar);
                    }}
                    disabled={disabled[i]}
                  />
                ))}
              </div>
            )}
          />
          {Object.keys(formState.errors).length > 0 && (
            <div>
              {Object.keys(formState.errors).map((error, i) => (
                <Typography color="error" key={i}>
                  {(formState as any).errors[error].message}
                </Typography>
              ))}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              type="submit"
            >
              Done
            </Button>
          </div>
        </form>
      </Paper>
    </Modal>
  );
}
