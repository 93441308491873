import {
  createStyles,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useContext, useState } from 'react';
import { fetchOrgInfo, UserContext } from '../context/UserStateManager';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownmenu: {
      maxHeight: '400px',
      overflowY: 'auto',
    },
  }),
);

function OrganizationSelection() {
  const userCon = useContext(UserContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const [fetching, setFetching] = useState(false);
  const [hideNav, setHideNav] = useState(false)

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{display: userCon.state.user.orgs.length == 0 ? 'none' : 'flex', alignItems: 'center', justifyContent: 'space-between'}}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography>
          {userCon.state.currentOrg?.org?.name ?? 'Select an Organization'}
        </Typography>
        <ExpandMoreOutlinedIcon />
      </div>

      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onExited={() => {
          if (anchorEl === null) setSearch('');
        }}
      >
        <Paper elevation={0}>
          {userCon.state.user.orgs.length > 9 && (
            <TextField
              inputRef={(input) => input && input.focus()}
              label="Search"
              value={search}
              style={{ margin: '8px 16px', width: '91%' }}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: '#999999' }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <div className={classes.dropdownmenu}>
            {userCon.state.user.orgs
              .filter((obj) =>
                search === ''
                  ? true
                  : obj.org.name.toLowerCase().includes(search.toLowerCase()),
              )
              .map((ele) => (
                <MenuItem
                  key={ele.org._id}
                  disabled={
                    fetching ||
                    userCon.state.currentOrg?.org._id === ele.org._id
                  }
                  onClick={() => {
                    setAnchorEl(null);
                    if (userCon.state.currentOrg?.org._id !== ele.org._id) {
                      setFetching(true);
                      fetchOrgInfo(userCon, ele.org._id)
                        .then((org) =>
                          userCon.setState({
                            ...userCon.state,
                            currentOrg: { ...ele, org },
                            currentDevice: null,
                          }),
                        )
                        .finally(() => setFetching(false));
                    }
                  }}
                >
                  <Typography>
                    {ele.org.name.length > 40
                      ? ele.org.name.slice(0, 40) + '...'
                      : ele.org.name}
                  </Typography>
                </MenuItem>
              ))}
          </div>
        </Paper>
      </Menu>
    </div>
  );
}

export default OrganizationSelection;
