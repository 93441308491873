import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';

type props = {
  showBanner?: boolean,
  lineup?: any
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Content: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 10,
    },
    mobilePadding: {
      [theme.breakpoints.down('md')]: {
        padding: '10',
      },
    },
    colorColumn: {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: '80px',
    },
    colorBlob: {
      width: '16px',
      height: '16px',
      margin: '4px 8px',
      padding: '3px',
      borderRadius: '6px',
      border: '1px solid',
    },
  }),
);

export default function OrgLineupOverrideBanner({
  showBanner,
  lineup
}: props) {
  const classes = useStyles();
  return (
    <div>
      {showBanner && (
        <div>
        <Paper className={classes.Content} style={{ backgroundColor: 'yellow',}}>
          <Typography variant="h3" style={{ marginBottom: '8px' }}>
            Organization Lineup Sync is currently active
          </Typography>
          <div style={{ margin: '0' }}>
            <Typography variant="subtitle1">
              Current Lineup: {lineup}
            </Typography>
          </div>
        </Paper>
        <br/>
        </div>
        )}
    </div>
   );
}
