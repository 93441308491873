import {
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useContext, useEffect, useRef } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import DeviceSettings, {
  MusicShowConfiguration,
  MusicControl, SystemSettings,
} from '../devices/DeviceSettingsObject';
import { ConditionType, WhiteCeilingType, WhiteCeilingTypeMap } from '../Enums';
import ConditionSelection from './ConditionSelection';
import { DeviceSettingsContext, getUsedInputs } from './DevicePage';
import MusicShowLineupList from "./MusicShowLineupList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: '8px',
      backgroundColor: '#707070',
    },
    button: {
      margin: '16px auto 0 auto',
      height: 60,
    },
  }),
);

const checkInputNum = (dups: number[], newCond: any) =>
  dups.indexOf(newCond.condition?.input) !== -1;

const doesntNeedObj = (type: ConditionType) =>
  type === ConditionType.ALWAYS_OFF || type === ConditionType.ALWAYS_ON;

function trimConditionObjects(config: DeviceSettings) {
  if (doesntNeedObj(config.musicControl.musicShowEnable.conditionType)) {
    delete config.musicControl.musicShowEnable.condition;
  }
}

function checkForDuplicateInputs(
  formObject: UseFormReturn<MusicControl>,
  newSettings: DeviceSettings,
) {
  let noErrors = true;
  const duplicates: number[] = [];
  getUsedInputs(newSettings).forEach((input, _, ar) => {
    if (ar.filter((i) => i === input).length > 1) duplicates.push(input);
  });

  if (checkInputNum(duplicates, newSettings.musicControl.musicShowEnable)) {
    noErrors = false;
    formObject.setError('musicShowEnable.condition.input', {
      type: 'InputInUse',
      message: 'Input Already in Use',
    });
  }

  return noErrors;
}

export default function MusicControlForm() {
  const classes = useStyles();
  const { configuration, setConfiguration, updating } = useContext(DeviceSettingsContext);
  const formObject = useForm<MusicControl>();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const watch = formObject.watch((_, info) => {
      if (formRef.current && info.type) {
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true }),
        );
      }
    });
    return () => watch.unsubscribe();
  }, []);

  return (
    <form
      ref={formRef}
      style={{ display: 'flex', flexDirection: 'column' }}
      onSubmit={formObject.handleSubmit((formData) => {

        formObject.clearErrors();
        const newSettings = {
          ...configuration,
          musicControl: {
            ...configuration.musicControl,
            ...formData,
          },
        };

        trimConditionObjects(newSettings);
        if (checkForDuplicateInputs(formObject, newSettings))
          setConfiguration(newSettings);
      })}
    >
      <Grid container spacing={3}>

        <Grid item xs={12} md={12}>
        <MusicShowLineupList
          showList={configuration.musicControl.showLineup}
          setShows={(showLineup: MusicShowConfiguration[]) =>
            setConfiguration(
              { ...configuration, musicControl: {...configuration.musicControl, showLineup: showLineup }}
            )
          }
          zone={configuration.musicControl.musicShowZone ?? 0}
          setZone={(zoneNum) =>
            setConfiguration({ ...configuration, musicControl: {...configuration.musicControl, musicShowZone: zoneNum }}
            )
          }
          disabled={
            updating
          }
        />

        </Grid>

        <Grid item xs={12} md={12}>
          <Paper>
            <Typography variant="h3">Music Lighting Enable</Typography>
            <Typography variant="h4">
              This setting activates the music lighting show line-up designed to
              market your site to potential customers. This function can be
              programmed to be always on or always off, activated by an input or
              activated during a selected time period.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <ConditionSelection<MusicControl>
              formObject={formObject}
              defaultVal={{
                conditionType: configuration.musicControl.musicShowEnable.conditionType,
                condition: configuration.musicControl.musicShowEnable.condition,
              }}
              basePath="musicShowEnable."
            />
          </Paper>
        </Grid>

      </Grid>
    </form>
  );
}
