import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { UserContext } from '../context/UserStateManager';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    usercard: {
      display: 'flex',
      alignItems: 'center',
    },
    intialsBubble: {
      height: '42px',
      width: '42px',
      backgroundColor: '#57B8FF',
      borderRadius: '42px',
      '& div': {
        margin: '10px auto',
        width: 'fit-content',
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: 700,
      },
    },
  }),
);

function UserProfileMenu() {
  const userCon = useContext(UserContext);
  const classes = useStyles();

  return (
    <div className={classes.usercard}>
      <div className={classes.intialsBubble}>
        <div>
          {userCon.state.user.firstName?.charAt(0) +
            userCon.state.user.lastName?.charAt(0)}
        </div>
      </div>
      <div style={{ width: '16px' }} />
      <Typography>
        {userCon.state.user.firstName + ' ' + userCon.state.user.lastName}
      </Typography>
    </div>
  );
}

export default UserProfileMenu;
