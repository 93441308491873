import { Button, FormControlLabel, TextField, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { DurationComponent } from "./DurationComponent";
import { useState } from "react";

const colors = ["Red", "Green", "Blue", "White", "Off"];

export type PixelFormValues = {
  duration: number,
  redPixels: string, greenPixels: string, bluePixels: string, whitePixels: string, offPixels: string
}

function parsePixels(pixelData: string[]) {
  return pixelData?.map((value) => {
    const pixels: number[] = [];
    if (value) {
      const pageRanges = value.split(",");
      for (const range of pageRanges) {
        const parts = range.trim().split("-");

        // Check if the range has exactly 2 parts and they are both valid numbers.
        if (parts.length === 2) {
          const start = parseInt(parts[0]);
          const end = parseInt(parts[1]);

          if (!isNaN(start) && !isNaN(end)) {
            if (end < start) {
              throw new Error("Start is greater than end");
            }

            for (let i = start; i <= end; i++) {
              pixels.push(i);
            }
          } else {
            throw new Error("Invalid range format");
          }
        }
        // Check if the range has exactly 1 part and it is a valid number.
        else if (parts.length === 1) {
          const page = parseInt(parts[0]);
          if (!isNaN(page)) {
            pixels.push(page);
          } else {
            throw new Error("Invalid number format");
          }
        }
        // Invalid range format.
        else {
          throw new Error("Invalid format");
        }
      }
    } else {
      pixels.push(0);
    }
    return pixels;
  });
}


export function PixelVariant({ makeRequest, on, stopTrouble, error }: { makeRequest: (pixels: number[][], duration: number) => Promise<void>, on: boolean, stopTrouble: () => void, error: string }) {
  const { control, handleSubmit, getValues } = useForm<PixelFormValues>()
  const [inputError, setInputError] = useState("");

  const onSubmit = (data: PixelFormValues) => {
    const pixelsToSendBack = parsePixels([data.redPixels, data.greenPixels, data.bluePixels, data.whitePixels, data.offPixels]);
    makeRequest(pixelsToSendBack, data.duration);
  };

  const checkErrors = () => {
    const data = getValues()
    try {
      const pixelsToSendBack = parsePixels([data.redPixels, data.greenPixels, data.bluePixels, data.whitePixels, data.offPixels]);
      const flattened = pixelsToSendBack.flat().filter(item => item !== 0);

      if (new Set(flattened).size !== flattened.length) {
        setInputError("Duplicates found! Please omit them");
      }
      else if (flattened.some(item => item > 385)) {
        setInputError("Values must be smaller than 385");
      }
      else {
        setInputError("");
      }
    }
    catch (err) {
      if (err instanceof Error)
        setInputError(err.message)
    }
  }

  const renderController = (color: string) => {
    return (
      <Controller
        key={color}
        name={`${color.toLowerCase()}Pixels` as keyof PixelFormValues}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            label={color}
            labelPlacement="start"
            style={{ maxWidth: '400px' }}
            onChange={onChange}
            value={value}
            control={<TextField type="text" onBlur={checkErrors} />}
          />
        )}
      />
    );
  };

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: "30px" }}>
        Enter pixel values and a duration. Pixel values can be between 1 and 384. Values can be entered as a single number, a list of numbers, a range of numbers or a combination.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {colors.map((color) => renderController(color))}
        <DurationComponent control={control} />
        <div style={{ display: "flex", justifyContent: "end", marginTop: "16px" }}>
          <Button type="submit" variant="contained" color="primary" disabled={on || error.length !== 0}>Submit</Button>
        </div>
        {inputError &&
          <div style={{ display: "flex", justifyContent: "end", marginTop: '10px' }}>
            <span style={{ color: "red" }}>{inputError}</span>
          </div>
        }
      </form >
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {on && <Button onClick={stopTrouble} variant="outlined" color="primary">Abort Troubleshoot</Button>}
      </div>
    </>
  );
}