import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      borderCollapse: 'collapse',
      textAlign: 'right',
    },
    td: {
      border: '1px solid black',
      padding: '2px 4px',
    },
  }),
);

export function ZoneTable({ pixels }: { pixels: number[][] }) {
  const classes = useStyles();

  try {
    return (
      <table className={classes.table}>
        {pixels.map((pixelArray, index) => (
          <tr>
            <td className={classes.td}>
              <b>{index + 1}</b>
            </td>
            {pixelArray.map((pixel) => (
              <td className={classes.td}>{pixel}</td>
            ))}
          </tr>
        ))}
      </table>
    );
  } catch (e) {
    return <Typography>Invalid zone json</Typography>;
  }
}
