import {
  Button,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { createRef, RefObject, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../context/UserStateManager';
import { DeviceSettingsContext } from './DevicePage';
import { Controller, useForm } from 'react-hook-form';
import ConfirmationModal from '../common/ConfirmationModal';
import { ControllerTypeMap, SysRole } from '../Enums';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { DeviceModel } from '../devices/Devices';
import StatusCodeResponse from '../common/StatusCodeResponse';
import DeviceSettings from '../devices/DeviceSettingsObject';
import { ZoneTable } from '../common/ZoneTable';
import { PixelMapTable } from '../common/PixelMapTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginTop: '8px',
      backgroundColor: '#707070',
    },
    modalPaper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10vh auto',
      width: '80%',
      maxHeight: '80%',
      overflowY: 'auto',
      gap: '10px',
    },
    tableContent: {
      minHeight: '200px',
      overflowX: 'auto',
      margin: theme.spacing(2),
    },
  }),
);

type props = {
  updateDevice: Function;
};

// const isPixelMap = (obj: any) => {
//   if (!Array.isArray(obj)) return false;
//   for (let ar of obj) {
//     if (!Array.isArray(ar)) return false;
//     for (let ele of ar) {
//       if (typeof ele !== 'number') return false;
//     }
//   }
//   return true;
// };

export default function AdvancedSettingsForm({ updateDevice }: props) {
  const classes = useStyles();
  const history = useHistory();
  const userCon = useContext(UserContext);
  const { configuration, setConfiguration, updating } = useContext(
    DeviceSettingsContext,
  );
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [settingsModalAccepted, setSettingsModalAccepted] = useState(false);
  const [settingsUploadError, setSettingsUploadError] = useState('');
  const [deviceChanged, setDeviceChanged] = useState(false);
  const [advancedChanged, setAdvancedChanged] = useState(false);
  const [pixelOpen, setPixelOpen] = useState(false);
  const [zonesOpen, setZonesOpen] = useState(false);
  const [infoResponse, setInfoResponse] = useState(0);
  const [loggingLevel, setLoggingLevel] = useState(0);
  const [logLevelStatus, setLogLevelStatus] = useState(0);
  const deviceForm = useForm<{
    name: string;
    location: string;
  }>();
  const advancedSettingsForm = useForm<{
    triggerCount: number;
    pixelMap: string;
    zones: string;
  }>();
  const [uploadedSettings, setUploadedSettings] = useState<DeviceSettings>();
  const uploadRef: RefObject<HTMLInputElement> = createRef();

  const downloadSettings = () => {
    const deviceId = userCon.state.currentDevice?.deviceID;
    const json = JSON.stringify(configuration, null, 2);
    const blob = new Blob([json], { type: 'text/json;' });
    const url = URL.createObjectURL(blob);
    const dl = document.createElement('a');
    dl.href = url;
    dl.setAttribute('download', `${deviceId}_${new Date().toISOString()}.json`);
    dl.click();
    dl.remove();
  };

  const removeDevice = async () => {
    const dto = {};
    const status = await updateDevice(dto, userCon.state.currentDevice?._id);

    if (status !== 200) {
      return;
    }

    history.push('/controller');
  };

  useEffect(() => {
    if (
      settingsModalAccepted &&
      !settingsModalOpen &&
      !uploadedSettings &&
      !settingsUploadError
    )
      uploadRef.current?.click();
  }, [
    settingsModalAccepted,
    settingsModalOpen,
    uploadRef,
    uploadedSettings,
    settingsUploadError,
  ]);

  return (
    <div>
      <Paper style={{ marginBottom: 16 }}>
        <Typography variant="h3">Controller Information</Typography>
        <Divider classes={{ root: classes.divider }} />
        <form
          onChange={(e) => setDeviceChanged(true)}
          onSubmit={deviceForm.handleSubmit(async (formData) => {
            const res = await fetchLogoutOn401(
              userCon,
              process.env.REACT_APP_BACKEND_URL +
              '/orgs/' +
              userCon.state.currentOrg?.org._id +
              '/deviceInfo/' +
              userCon.state.currentDevice?._id,
              {
                method: 'PATCH',
                headers: {
                  'Content-type': 'application/json',
                  Authorization: 'Bearer ' + userCon.state.jwtToken,
                },
                body: JSON.stringify(formData),
              },
            );
            if (res.ok) {
              userCon.setState({
                ...userCon.state,
                currentDevice: {
                  ...(userCon.state.currentDevice as DeviceModel),
                  name: formData.name,
                  location: formData.location,
                },
              });
              setDeviceChanged(false);
              setInfoResponse(0);
            } else {
              setInfoResponse(999);
            }
          })}
        >
          <Controller
            name="name"
            control={deviceForm.control}
            defaultValue={userCon.state.currentDevice?.name ?? ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControlLabel
                label="Name"
                labelPlacement="start"
                control={
                  <TextField
                    disabled={updating}
                    value={value}
                    onChange={(e) => {
                      setDeviceChanged(true);
                      onChange(e);
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                }
              />
            )}
            rules={{
              required: 'Required',
              maxLength: {
                value: 50,
                message: 'Must be less than 50 characters',
              },
            }}
          />
          <Controller
            name="location"
            control={deviceForm.control}
            defaultValue={userCon.state.currentDevice?.location ?? ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControlLabel
                label="Location"
                labelPlacement="start"
                control={
                  <TextField
                    disabled={updating}
                    value={value}
                    onChange={(e) => {
                      setDeviceChanged(true);
                      onChange(e);
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                }
              />
            )}
            rules={{
              maxLength: {
                value: 50,
                message: 'Must be less than 50 characters',
              },
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10px" }}>
            <Typography>DeviceID</Typography>
            <Typography>
              {userCon.state.currentDevice?.deviceID ?? ''}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Device Type</Typography>
            <Typography>
              {userCon.state.currentDevice?.configuration.controllerType && ControllerTypeMap[userCon.state.currentDevice?.configuration.controllerType]}
            </Typography>
          </div>
          {deviceChanged && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '24px',
              }}
            >
              <Button
                disabled={updating}
                className={classes.button}
                color="primary"
                type="submit"
                variant="contained"
              >
                Save Settings
              </Button>
            </div>
          )}
          <StatusCodeResponse
            statusCode={infoResponse}
            codeResponses={[
              {
                statusCode: 999,
                response:
                  'An error occured, unable to save controller information.',
              },
            ]}
          />
        </form>
      </Paper>
      {userCon.state.user.role !== SysRole.SYSTEM_USER && (
        <>
          <Paper style={{ marginBottom: 16 }}>
            <Typography variant="h3">Controller Setup</Typography>
            <Divider
              classes={{ root: classes.divider }}
              style={{ marginBottom: '16px' }}
            />
            <form
              onChange={(e) => setAdvancedChanged(true)}
              onSubmit={advancedSettingsForm.handleSubmit((formData) => {
                if (uploadedSettings) {
                  setConfiguration({
                    ...uploadedSettings,
                  });
                } else {
                  setConfiguration({
                    ...configuration,
                    settings: {
                      ...configuration.settings,
                      triggerCount: formData.triggerCount,
                      pixelMap: JSON.parse(formData.pixelMap),
                      zones: JSON.parse(formData.zones),
                    },
                  });
                  advancedSettingsForm.reset({
                    triggerCount: formData.triggerCount,
                    pixelMap: formData.pixelMap,
                    zones: formData.zones,
                  });
                }
                setAdvancedChanged(false);
              })}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h3">Trigger Count</Typography>
                  <Controller
                    name="triggerCount"
                    defaultValue={configuration.settings.triggerCount}
                    control={advancedSettingsForm.control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        disabled={updating}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          setAdvancedChanged(true);
                        }}
                      >
                        {[0, 8, 16, 24, 32, 48].map((val) => (
                          <MenuItem key={val} value={val}>
                            {`${val} inputs`}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h3">Zones</Typography>
                  <Controller
                    name="zones"
                    defaultValue={JSON.stringify(configuration.settings.zones)}
                    control={advancedSettingsForm.control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div>
                        <Modal
                          style={{ display: 'flex', flexDirection: 'column' }}
                          open={zonesOpen}
                          onClose={() => setZonesOpen(false)}
                        >
                          <Paper className={classes.modalPaper}>
                            {configuration.settings.zones.map((zone, i) => (
                              <Paper key={i}>
                                <Typography variant="h1">
                                  {zone.name}
                                </Typography>
                                <div className={classes.tableContent}>
                                  <ZoneTable pixels={zone.pixels} />
                                </div>
                              </Paper>
                            ))}
                            <Button
                              className={classes.button}
                              style={{
                                position: 'sticky',
                                alignSelf: 'flex-end',
                                bottom: '0',
                              }}
                              color="primary"
                              component="label"
                              variant="contained"
                              onClick={() => setZonesOpen(false)}
                            >
                              Close
                            </Button>
                          </Paper>
                        </Modal>
                        <Button
                          disabled={updating}
                          className={classes.button}
                          color="secondary"
                          component="label"
                          variant="contained"
                          onClick={() => setZonesOpen(true)}
                        >
                          View Current Zones
                        </Button>
                        <br />
                        <Button
                          disabled={updating}
                          className={classes.button}
                          component="label"
                          color="primary"
                          variant="contained"
                        >
                          Upload Zones
                          <input
                            disabled={updating}
                            type="file"
                            hidden
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files && files?.length > 0) {
                                let reader = new FileReader();
                                reader.onload = (event) => {
                                  onChange(event?.target?.result as string);
                                };
                                reader.readAsText(files[0]);
                              }
                            }}
                          />
                        </Button>
                        {!!error && (
                          <Typography variant="h3" color="error">
                            {error.message}
                          </Typography>
                        )}
                      </div>
                    )}
                    rules={{
                      validate: (value) => {
                        try {
                          JSON.parse(value);
                        } catch (e) {
                          return 'File must be valid JSON.';
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h3">Pixel Map</Typography>
                  <Controller
                    name="pixelMap"
                    defaultValue={JSON.stringify(
                      configuration.settings.pixelMap,
                    )}
                    control={advancedSettingsForm.control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div>
                        <Modal
                          open={pixelOpen}
                          style={{ display: 'flex', flexDirection: 'column' }}
                          onClose={() => setPixelOpen(false)}
                        >
                          <Paper className={classes.modalPaper}>
                            <Paper
                              elevation={3}
                              className={classes.tableContent}
                            >
                              <PixelMapTable
                                pixelMap={configuration.settings.pixelMap}
                              />
                            </Paper>
                            <Button
                              className={classes.button}
                              color="primary"
                              component="label"
                              variant="contained"
                              onClick={() => setPixelOpen(false)}
                            >
                              Close
                            </Button>
                          </Paper>
                        </Modal>
                        <Button
                          disabled={updating}
                          className={classes.button}
                          color="secondary"
                          component="label"
                          variant="contained"
                          onClick={() => setPixelOpen(true)}
                        >
                          View Current Pixel Map
                        </Button>
                        <br />
                        <Button
                          disabled={updating}
                          className={classes.button}
                          color="primary"
                          component="label"
                          variant="contained"
                        >
                          Upload Pixel Map
                          <input
                            disabled={updating}
                            type="file"
                            hidden
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files && files?.length > 0) {
                                let reader = new FileReader();
                                reader.onload = (event) => {
                                  onChange(event?.target?.result as string);
                                };
                                reader.readAsText(files[0]);
                              }
                            }}
                          />
                        </Button>
                        {!!error && (
                          <Typography variant="h3" color="error">
                            {error.message}
                          </Typography>
                        )}
                      </div>
                    )}
                    rules={{
                      // validate: (value) =>
                      //   isPixelMap(value) || 'Incorrect File Format',
                      validate: (value) => {
                        try {
                          JSON.parse(value);
                        } catch (e) {
                          return 'File must be valid JSON.';
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h3">Device Settings</Typography>
                  <Button
                    disabled={updating}
                    className={classes.button}
                    color="secondary"
                    component="label"
                    variant="contained"
                    onClick={downloadSettings}
                  >
                    Download Settings
                  </Button>
                  <Button
                    disabled={updating}
                    className={classes.button}
                    color="primary"
                    component="label"
                    variant="contained"
                    onClick={() => {
                      if (!settingsModalAccepted) {
                        setSettingsModalOpen(true);
                        setSettingsUploadError('');
                      }
                    }}
                  >
                    Upload Device Settings
                    {settingsModalAccepted && (
                      <input
                        disabled={updating}
                        type="file"
                        ref={uploadRef}
                        hidden
                        onChange={(e) => {
                          const files = e.target.files;
                          if (files && files?.length > 0) {
                            let reader = new FileReader();
                            reader.onload = (event) => {
                              try {
                                const settings = JSON.parse(
                                  event?.target?.result as string,
                                );
                                setSettingsUploadError('');
                                setUploadedSettings(settings);
                              } catch (e) {
                                setSettingsUploadError(
                                  'File must be valid JSON.',
                                );
                              }
                            };
                            reader.readAsText(files[0]);
                          }
                        }}
                      />
                    )}
                  </Button>
                  {settingsUploadError && (
                    <Typography style={{ color: 'red' }}>
                      {settingsUploadError}
                    </Typography>
                  )}
                  <ConfirmationModal
                    title="Upload Device Settings"
                    message="This will overwrite this Device's settings. File contents are NOT validated."
                    confirmText="Continue"
                    onClose={() => setSettingsModalOpen(false)}
                    onConfirm={() => {
                      setSettingsModalAccepted(true);
                      setSettingsModalOpen(false);
                    }}
                    open={settingsModalOpen}
                  />
                </Grid>
                {advancedChanged && !settingsUploadError && (
                  <Button
                    disabled={updating}
                    className={classes.button}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Save Settings
                  </Button>
                )}
              </Grid>
            </form>
          </Paper>
          <Paper style={{ marginBottom: 16 }}>
            <div>
              <Typography variant="h3">Device Logging Level</Typography>
              <FormControlLabel
                style={{
                  justifyContent: 'start',
                  width: 'fit-content',
                  marginBottom: '10px',
                }}
                label="Logging Level: "
                labelPlacement="start"
                control={
                  <Select
                    style={{ marginLeft: '10px' }}
                    disabled={updating}
                    value={loggingLevel}
                    onChange={(e) => {
                      setLogLevelStatus(0);
                      setLoggingLevel(e.target.value as number);
                    }}
                  >
                    {[0, 1, 2, 3, 4, 5].map((val) => (
                      <MenuItem
                        key={val}
                        value={val}
                      >{`Level ${val}`}</MenuItem>
                    ))}
                  </Select>
                }
              />
              <Button
                disabled={updating}
                variant="contained"
                color="primary"
                onClick={async () => {
                  try {
                    const res = await fetchLogoutOn401(
                      userCon,
                      `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state?.currentDevice?._id}/setloglevel`,
                      {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ' + userCon.state.jwtToken,
                        },
                        body: JSON.stringify({
                          level: loggingLevel,
                          timeOut: 180,
                        }),
                      },
                    );
                    setLogLevelStatus(res.status);
                  } catch (e) {
                    setLogLevelStatus(500);
                  }
                }}
              >
                Set Logging Level
              </Button>
              {logLevelStatus === 200 && (
                <Typography
                  style={{ marginTop: '10px', color: 'green' }}
                  variant="subtitle1"
                >{`Logging level was set to ${loggingLevel}`}</Typography>
              )}
              {logLevelStatus > 200 && (
                <Typography
                  style={{ marginTop: '10px', color: 'red' }}
                  variant="subtitle1"
                >
                  An error occurred when changing the logging level
                </Typography>
              )}
            </div>
          </Paper>
          <Paper>
            <div>
              <Typography variant="h3">Remove Controller</Typography>

              <Typography variant="h4" style={{ paddingTop: '16px' }}>
                Remove this controller from this organization.
              </Typography>

              <Button
                disabled={updating}
                variant="contained"
                style={{
                  margin: '16px auto 0px auto',
                  backgroundColor: '#F0142F',
                  color: '#ffffff',
                }}
                onClick={() => setRemoveModalOpen(true)}
              >
                Remove Controller
              </Button>

              <ConfirmationModal
                open={removeModalOpen}
                onClose={() => setRemoveModalOpen(false)}
                onConfirm={() => removeDevice()}
                title="Remove Controller?"
                message="Are you sure you want to remove this controller from this organization?"
                confirmText="Remove"
              />
            </div>
          </Paper>
        </>
      )}
    </div>
  );
}
