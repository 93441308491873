import {
  AppBar,
  Button,
  createStyles,
  FormControlLabel,
  IconButton,
  Input,
  ListItemText,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Controller, useForm } from 'react-hook-form';
import { OrgRole, OrgRoleMap, SysRole, SysRoleMap } from '../Enums';
import { UserModel } from './Users';
import { useContext, useEffect, useState } from 'react';
import StatusCodeResponse from '../common/StatusCodeResponse';
import { UserContext } from '../context/UserStateManager';
import { DeviceModel } from '../devices/Devices';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';

type props = {
  closeDialog: Function;
  userData: null | UserModel;
  admin?: boolean;
  submitUser: Function;
  resetUserPassword: Function;
};

interface UserFormModel {
  email: string;
  orgs: string[];
  devices: string[];
  role: SysRole | OrgRole;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      height: `68px`,
      borderBottom: '1px solid #e0e0e0',
    },
    formRoot: {
      minHeight: '100%',
      backgroundColor: '#F4F7FC',
    },
    // necessary for content to be below app bar
    toolbar: { height: '68px' },
    formContainer: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      maxWidth: `min(500px, calc(100% - ${theme.spacing(2) * 2}px))`,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    resendInvite: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    userInfo: {
      margin: theme.spacing(2) + 'px 0',
    },
    modal: {
      margin: theme.spacing(2),
    },
  }),
);

export function UserForm({
  admin,
  userData,
  closeDialog,
  submitUser,
  resetUserPassword,
}: props) {
  const classes = useStyles();
  const userCon = useContext(UserContext);
  const { handleSubmit, control, watch } = useForm<UserFormModel>();
  const [statusCode, setStatusCode] = useState(0);
  const [passwordReset, setPasswordReset] = useState(false);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [modal, setModal] = useState({ open: false, message: '' });
  const role = watch('role');

  useEffect(() => {
    // get list of devices
    fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
        '/orgs/' +
        userCon.state.currentOrg?.org._id +
        '/devices',
      {
        headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      },
    )
      .then((res) => {
        if (res.status !== 200) setStatusCode(999);
        else return res.json();
      })
      .then((obj: DeviceModel[]) => setDevices(obj));
  }, []);

  async function resetPassword(user: UserModel | null) {
    if (!user) return;

    const status = await resetUserPassword(user);

    if (status === 201) setPasswordReset(true);
  }

  async function submit(userFormData: UserFormModel) {
    let status;
    if (admin) {
      // admin settings
      let userDTO: any = {};
      if (userData) {
        // edit user
        // reformat user data for DTO
        let orgs: any = userData.orgs
          .map((obj) => ({
            org: obj.org._id,
            role: obj.role,
          }))
          .filter(({ org }) => userFormData.orgs.indexOf(org) !== -1); // filter user data for removal from orgs
        // merge existing org & roles with new orgs & ORG_USER role
        for (let id of userFormData.orgs) {
          let exists = false;
          orgs.forEach((obj: any) => {
            if (obj.org === id) exists = true;
          });
          if (!exists) {
            orgs.push({ org: id, role: OrgRole.ORG_USER });
          }
        }
        // insert the user's id for PATCH
        userDTO = {
          ...userFormData,
          _id: userData._id,
          orgs,
        };
        if (userCon.state.user.role === SysRole.SYSTEM_MANAGER)
          delete userDTO.role;
      } else {
        // new user
        // populate roles into org relations
        userDTO = {
          ...userFormData,
          firstName: '',
          lastName: '',
          orgs: userFormData.orgs.map((id) => ({
            org: id,
            role: OrgRole.ORG_USER,
          })),
        };
      }
      status = await submitUser(userDTO);
    } else {
      // org settings
      const userOrgDTO = {
        devices: userFormData.devices ?? [],
        orgRole: userFormData.role,
      };
      if (userData) {
        // edit org user
        status = await submitUser(userData._id, userOrgDTO);
      } else {
        // invite org user
        status = await submitUser(userFormData.email, userOrgDTO);
      }
    }

    if (status === 201 || status === 200) {
      if (!userData)
        setModal({
          open: true,
          message: `User successfully ${admin ? 'created' : 'added'}.`,
        });
      else closeDialog();
      return;
    } else if (status === 500) {
      if (!userData)
        setModal({
          open: true,
          message: `User successfully ${
            admin ? 'created' : 'added'
          }, email invitation send failed.`,
        });
      else closeDialog();
      return;
    }

    setStatusCode(status);
  }

  return (
    <div className={classes.formRoot}>
      <AppBar
        color="secondary"
        className={classes.appBar}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {userData === null ? 'Invite User' : 'Edit User'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <div className={classes.formContainer}>
        <Paper>
          <form
            className={classes.form}
            onSubmit={handleSubmit((user) => submit(user))}
          >
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              User Information
            </Typography>
            {userData && (
              <div>
                <Typography className={classes.userInfo} variant="h3">
                  {`Name: ${userData.firstName + ' ' + userData.lastName}`}
                </Typography>
                <Typography className={classes.userInfo} variant="h3">
                  {`Email: ${userData.email}`}
                </Typography>
              </div>
            )}

            {userData && !userData.hasAcceptedInvite && (
              <div className={classes.resendInvite}>
                <Typography variant="h6">
                  This user has not accepted your invite
                </Typography>
                <Button
                  color="primary"
                  variant="text"
                  disabled={passwordReset}
                  onClick={() => resetPassword(userData)}
                >
                  Resend Invite
                </Button>
              </div>
            )}

            {!userData && (
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    label="Email Address"
                    labelPlacement="start"
                    control={
                      <TextField
                        type="email"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    }
                  />
                )}
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 320,
                    message: 'Must be less than 320 characters',
                  },
                }}
              />
            )}

            {admin ? (
              <Controller
                name="orgs"
                control={control}
                defaultValue={
                  userData ? userData.orgs.map((ele) => ele.org._id) : []
                }
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label="Organizations"
                    labelPlacement="start"
                    control={
                      <Select
                        multiple
                        value={value}
                        onChange={onChange}
                        input={<Input />}
                        style={{ width: '200px' }}
                        renderValue={(selected: unknown) => {
                          const value = (selected as string[])
                            .map((id) => {
                              for (let obj of userCon.state.user.orgs) {
                                if (obj.org._id === id) return obj.org.name;
                              }
                              return 'ORG NAME NOT FOUND ERROR';
                            })
                            .join(', ');
                          return value.length > 23
                            ? value.slice(0, 23) + '...'
                            : value;
                        }}
                        MenuProps={{
                          getContentAnchorEl: null,
                        }}
                      >
                        {userCon.state.user.orgs.map((element) => (
                          <MenuItem
                            key={element.org._id}
                            value={element.org._id}
                          >
                            <ListItemText primary={element.org.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                )}
                rules={{ required: false }}
              />
            ) : (
              role === OrgRole.ORG_USER && (
                <Controller
                  name="devices"
                  control={control}
                  defaultValue={
                    userData
                      ? userData.orgs.find(
                          (obj) =>
                            obj.org._id === userCon.state?.currentOrg?.org?._id,
                        )?.devices
                      : []
                  }
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      label="Devices"
                      labelPlacement="start"
                      control={
                        <Select
                          value={value}
                          onChange={onChange}
                          input={<Input />}
                          style={{ width: '200px' }}
                          multiple
                          renderValue={(selected: unknown) => {
                            const value = (selected as string[])
                              .map(
                                (id) =>
                                  devices.find((dev) => dev._id === id)?.name,
                              )
                              .join(', ');
                            return value.length > 23
                              ? value.slice(0, 23) + '...'
                              : value;
                          }}
                          MenuProps={{
                            getContentAnchorEl: null,
                          }}
                        >
                          {devices.map((device) => (
                            <MenuItem key={device._id} value={device._id}>
                              {device.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  )}
                  rules={{ required: false }}
                />
              )
            )}

            <Controller
              name="role"
              control={control}
              defaultValue={
                userData
                  ? admin
                    ? userData.role
                    : userData.orgs.filter(
                        (ele) =>
                          ele.org._id === userCon.state.currentOrg?.org._id,
                      )[0].role
                  : Object.entries(admin ? SysRole : OrgRole)[0][0]
              }
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="User Type"
                  labelPlacement="start"
                  control={
                    <Select
                      value={value}
                      onChange={onChange}
                      disabled={
                        admin &&
                        userCon.state.user.role === SysRole.SYSTEM_MANAGER
                      }
                    >
                      {Object.keys(admin ? SysRole : OrgRole).map((role, i) => (
                        <MenuItem key={role + i} value={role}>
                          {admin
                            ? SysRoleMap[role as SysRole]
                            : OrgRoleMap[role as OrgRole]}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              )}
              rules={{ required: 'Required' }}
            />

            <Button
              color="primary"
              style={{ marginTop: '16px' }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
          <StatusCodeResponse
            statusCode={statusCode}
            codeResponses={[
              {
                statusCode: 409,
                response: 'Duplicate Email Found',
              },
              {
                statusCode: 999,
                response: 'Error Fetching Devices',
              },
            ]}
          />
        </Paper>
      </div>
      <Modal
        className={classes.modal}
        open={modal.open}
        onClose={() => {
          setModal({ ...modal, open: false });
          closeDialog();
        }}
      >
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '20vh auto',
            width: 'fit-content',
          }}
        >
          <Typography variant="h3">{modal.message}</Typography>
          <Button
            style={{ margin: '16px auto 8px auto', width: 'fit-content' }}
            color="primary"
            variant="contained"
            onClick={() => {
              setModal({ ...modal, open: false });
              closeDialog();
            }}
          >
            Ok
          </Button>
        </Paper>
      </Modal>
    </div>
  );
}

export default UserForm;
