import {
  AppBar,
  createStyles,
  Drawer,
  IconButton,
  makeStyles,
  Toolbar,
  Hidden,
  Theme,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useContext, useState } from 'react';
import DrawerContent from './DrawerContent';
import colorwashLogo from '../../assets/images/color-wash-logo-vertical.png';
import { UserContext } from '../context/UserStateManager';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const drawerWidth = 295;
const breakpoint = 'md';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up(breakpoint)]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up(breakpoint)]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      height: `68px`,
      borderBottom: '1px solid #e0e0e0',
    },
    toolbar: {
      display: 'flex',
      [theme.breakpoints.up(breakpoint)]: {
        justifyContent: 'space-between',
        // flexDirection: 'row-reverse',
      },
    },
    menuButton: {
      [theme.breakpoints.up(breakpoint)]: {
        display: 'none',
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    colorwashlogo: {
      marginLeft: theme.spacing(4),
      width: drawerWidth - theme.spacing(8),
      marginTop: 'auto',
      marginBottom: theme.spacing(4),
      // position: 'absolute',
      // bottom: theme.spacing(4),
    },
  }),
);

function checkPathForTitle(path: string, orgName: string | undefined) {
  if (path === '/admin') return 'Admin';
  if (path === '/user') return 'Profile';
  if (path === '/register') return 'Register Controller';
  else return orgName ?? '';
}

function Navigation() {
  const userCon = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [title, setTitle] = useState(
    checkPathForTitle(
      history.location.pathname,
      userCon.state.currentOrg?.org.name,
    ),
  );

  useEffect(() => {
    setTitle(
      checkPathForTitle(
        history.location.pathname,
        userCon.state.currentOrg?.org.name,
      ),
    );
    return history.listen((location) =>
      setTitle(
        checkPathForTitle(
          location.pathname,
          userCon.state.currentOrg?.org.name,
        ),
      ),
    );
  }, [userCon.state.currentOrg]);

  return (
    <>
      <AppBar
        color="secondary"
        className={classes.appBar}
        position="fixed"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileOpen(!mobileOpen)}
            className={classes.menuButton}
          >
            <MenuIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">{title}</Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={document.body}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerContent closeDrawer={() => setMobileOpen(!mobileOpen)} />
            <img
              className={classes.colorwashlogo}
              src={colorwashLogo}
              alt="Colorwash"
            />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DrawerContent />
            <img
              className={classes.colorwashlogo}
              src={colorwashLogo}
              alt="Colorwash"
            />
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

export default Navigation;
