import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusCodeResponse: {
      padding: '0',
      display: 'flex',
      justifyContent: 'space-around',
    },
  }),
);

type CodeResponses = {
  statusCode: number;
  response: string;
}[];

type Props = {
  statusCode: number;
  codeResponses: CodeResponses;
};



export const StatusCodeResponse = ({ statusCode, codeResponses }: Props) => {
  const classes = useStyles();

  for (const codeResponse of codeResponses) {
    if (statusCode === codeResponse.statusCode) {
      return (
        <div className={classes.statusCodeResponse}>
          <Typography variant="subtitle1" color="error">{ codeResponse.response }</Typography>
        </div>
      );
    }
  }

  return null;
};

export default StatusCodeResponse;
