import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  Paper,
  TextField,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ShowConfiguration } from '../devices/DeviceSettingsObject';
import { ShowConfigMap, ShowName, ShowNameMap } from '../Enums';
import MultiColorPicker, { WHITE } from './MultiColorPicker';
import { CloseOutlined, PlayArrow } from '@material-ui/icons';

type props<ControlType extends FieldValues> = {
  control: Control<ControlType>;
  setBlockSubmit: (arg0: boolean) => void;
  outsideEvent: null | any;
  showData: null | ShowConfiguration;
  basePath: string;
  noDuration?: boolean;
};

function getYouTubeVideoID(url: string) {
  // Create a URL object from the input URL
  const urlObject = new URL(url);

  // Extract the value of the 'v' parameter
  const videoID = urlObject.searchParams.get("v");

  return videoID;
}

export function trimShowFields(
  show: ShowConfiguration,
  removeDuration?: boolean,
) {
  if (ShowConfigMap[show.showName].maxColors === 0) delete show.colors;
  if (!ShowConfigMap[show.showName].direction) delete show.backward;
  if (!ShowConfigMap[show.showName].speed) delete show.speed;
  if (removeDuration) delete show.duration;
  else show.duration = parseFloat(show.duration as unknown as string);
}

export default function ShowForm<ControlType extends FieldValues>({
  control,
  showData,
  setBlockSubmit,
  outsideEvent,
  basePath,
  noDuration,
}: props<ControlType>) {
  const [selectedShow, setSelectedShow] = useState(
    showData?.showName ?? ShowName.SOLID,
  );
  const [duration, setDuration] = useState(
    showData?.duration
      ? [
        Math.floor(showData.duration) + '',
        Math.round(60 * (showData.duration % 1)) + '',
      ]
      : ['', ''],
  );
  const [showVideoPreview, setShowVideoPreview] = useState(false)


  const matches = useMediaQuery('(min-width: 900px)')

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: matches ? '40%' : '90%',
    transform: 'translate(-50%, -50%)',
    p: 4,
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
        <Controller
          name={(basePath + 'showName') as Path<ControlType>}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label="Show Type:"
              labelPlacement="start"
              style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
              control={
                <Select
                  value={value}
                  onChange={(e) => {
                    setSelectedShow(e.target.value as ShowName);
                    onChange(e);
                  }}
                >
                  {Object.keys(ShowNameMap).map((val) => (
                    <MenuItem key={val} value={val}>
                      {ShowNameMap[val as ShowName]}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          )}
        />
        {ShowConfigMap[selectedShow].videoUrl.length > 0 &&
          <IconButton onClick={() => setShowVideoPreview(true)}><PlayArrow /></IconButton>
        }
      </div>

      {ShowConfigMap[selectedShow].videoUrl.length > 0 &&
        <Modal open={showVideoPreview}>
          <Paper style={style}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
              <span>Video Preview:</span>
              <IconButton onClick={() => setShowVideoPreview(false)} style={{ padding: "2px" }}><CloseOutlined /></IconButton>
            </div>
            <div style={{ width: "100%", height: matches ? "25vw" : "50vw", marginBottom: "5px" }}>
              {ShowConfigMap[selectedShow].videoUrl && <iframe src={`https://www.youtube.com/embed/${getYouTubeVideoID(ShowConfigMap[selectedShow].videoUrl)}`} title={ShowConfigMap[selectedShow].id.toString()} style={{ width: "100%", height: "100%" }}>Watch Preview</iframe>}
            </div>
          </Paper>
        </Modal>
      }
      {
        ShowConfigMap[selectedShow].maxColors > 0 && (
          <Controller
            name={(basePath + 'colors') as Path<ControlType>}
            control={control}
            render={({ field: { onChange, value } }) => (
              <MultiColorPicker
                minColors={ShowConfigMap[selectedShow].minColors}
                maxColors={ShowConfigMap[selectedShow].maxColors}
                value={(value ?? [...WHITE]) as [number, number, number][]}
                onChange={onChange}
                blockSubmit={setBlockSubmit}
                outsideEvent={outsideEvent}
              />
            )}
          />
        )
      }
      {
        ShowConfigMap[selectedShow].speed && (
          <Controller
            name={(basePath + 'speed') as Path<ControlType>}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControlLabel
                label="Speed:"
                labelPlacement="start"
                control={
                  <Select value={value} onChange={onChange}>
                    {Array(10)
                      .fill(1)
                      .map((_, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {index + 1 + ''}
                        </MenuItem>
                      ))}
                  </Select>
                }
              />
            )}
            rules={{
              validate: (value) => !isNaN(parseInt(value)) || 'Speed Required',
            }}
          />
        )
      }
      {ShowConfigMap[selectedShow].speed && <div style={{ width: '32px' }} />}
      {
        !noDuration && (
          <Controller
            name={(basePath + 'duration') as Path<ControlType>}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControlLabel
                label="Run Time (mm:ss):"
                labelPlacement="start"
                control={
                  <div>
                    <TextField
                      type="number"
                      value={duration[0]}
                      onChange={(e) => {
                        const t = [e.target.value, duration[1]];
                        setDuration(t);
                        e.target.value =
                          parseInt(t[0]) + parseInt(t[1]) / 60 + '';
                        onChange(e);
                      }}
                      error={!!error}
                      helperText={
                        error &&
                          error.type === 'required' &&
                          isNaN(parseInt(duration[0]))
                          ? error.message
                          : null
                      }
                      InputProps={{
                        inputProps: { min: 0, max: 1440 },
                      }}
                    />
                    <span
                      style={{
                        margin: '4px',
                        lineHeight: '2',
                      }}
                    >
                      :
                    </span>
                    <TextField
                      type="number"
                      value={duration[1]}
                      onChange={(e) => {
                        const t = [duration[0], e.target.value];
                        setDuration(t);
                        e.target.value =
                          parseInt(t[0]) + parseInt(t[1]) / 60 + '';
                        onChange(e);
                      }}
                      error={!!error}
                      helperText={
                        error &&
                          (isNaN(parseInt(duration[1])) ||
                            error.type !== 'required')
                          ? error.message
                          : null
                      }
                      InputProps={{
                        inputProps: { min: 0, max: 55, step: 5 },
                      }}
                    />
                  </div>
                }
              />
            )}
            rules={{
              required: 'Run Time Required',
              validate: (value) => {
                if (isNaN(parseFloat(value))) return 'Run time must be a number';
                if (parseFloat(value) < 5 / 60)
                  return 'Minimum duration is 5 seconds.';
              },
            }}
          />
        )
      }
      {
        ShowConfigMap[selectedShow].direction && (
          <Controller
            name={(basePath + 'backward') as Path<ControlType>}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControlLabel
                label="Backwards"
                style={{ display: 'initial' }}
                control={
                  <Checkbox
                    checked={value as boolean}
                    value={value}
                    color="primary"
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        )
      }
    </div >
  );
}
