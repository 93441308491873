import useTitle from '../../hooks/useTitle.hook';
import TabNavigation from '../navigation/TabNavigation';
import Users from '../users/Users';
import OrgDefaults from './OrgDefaults';
import OrgLineupList from './OrgLineupList';
import OrgSecurity from './OrgSecurity';
import { LineupType } from '../Enums';

function OrgSettings() {
  useTitle('Organization Settings');
  return (
    <div>
      <TabNavigation
        title="Organization Settings"
        content={[
            { title: 'USERS', component: <Users /> },
            { title: 'DEFAULTS', component: <OrgDefaults /> },
            { title: 'Multi-Site Configuration', component: <OrgLineupList lineupType={LineupType.MOOD_LIGHTING} /> },
            { title: 'Music Show Lineups', component: <OrgLineupList lineupType={LineupType.MUSIC_LIGHTING}/> },
            { title: 'SECURITY' , component: <OrgSecurity/> },
        ]}
      />
    </div>
  );
}
export default OrgSettings;
