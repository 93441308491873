import {
  Button,
  createStyles,
  FormControlLabel,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type AcceptResponse = {
  success: boolean;
  message?: string;
};

type props = {
  open: boolean;
  onAccept: (id: string) => Promise<AcceptResponse>;
  onClose: Function;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      margin: theme.spacing(2),
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '20vh auto',
      width: 'fit-content',
      minWidth: '250px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 8,
    },
  }),
);

export default function NewLineupModal({ open, onAccept, onClose }: props) {
  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { name: '' },
  });
  const [hasError, setHasError] = useState(false);
  const [acceptResponseMessage, setAcceptResponseMessage] = useState('');

  const modalClose = () => {
    setSaved(false);
    setValue('name', '');
    setHasError(false);
    setAcceptResponseMessage('');
    onClose();
  };

  return (
    <Modal className={classes.modal} open={open} onClose={modalClose}>
      <Paper className={classes.paper}>
        <Typography variant="h2">New Show Lineup</Typography>
        {!saved && (
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(({ name }) => {
                onAccept(name).then(({ success, message }) => {
                  if (success) {
                    setSaved(true);
                  } else {
                    setHasError(true);
                  }
                  setAcceptResponseMessage(message || '');
                });
              })(e);
            }}
          >
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Save as:"
                  labelPlacement="start"
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      style={{ marginLeft: '8px' }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'Name Required',
                maxLength: {
                  value: 50,
                  message: 'Cannot exceed 50 characters.',
                },
              }}
            />
            <div className={classes.buttons}>
              <Button
                variant="text"
                color="secondary"
                style={{ marginRight: '16px', color: '#818387' }}
                onClick={modalClose}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </div>
            {hasError && (
              <div>
                <Typography>{acceptResponseMessage}</Typography>
              </div>
            )}
          </form>
        )}
        {saved && (
          <>
            <Typography variant="subtitle2">{acceptResponseMessage}</Typography>
            <div className={classes.buttons}>
              <Button variant="contained" color="primary" onClick={modalClose}>
                Close
              </Button>
            </div>
          </>
        )}
      </Paper>
    </Modal>
  );
}
