import {
  AppBar,
  Button,
  createStyles,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import StatusCodeResponse from '../common/StatusCodeResponse';
import { OrgModel } from './Orgs';

type props = {
  orgData: null | OrgModel;
  closeDialog: Function;
  submitOrg: Function;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      height: `68px`,
      borderBottom: '1px solid #e0e0e0',
    },
    formRoot: {
      minHeight: '100%',
      backgroundColor: '#F4F7FC',
    },
    // necessary for content to be below app bar
    toolbar: { height: '68px' },
    formContainer: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      maxWidth: `min(500px, calc(100% - ${theme.spacing(2) * 2}px))`,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  }),
);

export function OrgForm({ orgData, closeDialog, submitOrg }: props) {
  const classes = useStyles();
  const { handleSubmit, control } = useForm<OrgModel>();
  const [statusCode, setStatusCode] = useState(0);

  async function submit(org: OrgModel) {
    if (orgData) {
      org._id = orgData._id;
    }
    const status = await submitOrg(org);
    if (status === 201 || status === 200) {
      closeDialog();
      return;
    }
    setStatusCode(status);
  }

  return (
    <div className={classes.formRoot}>
      <AppBar
        color="secondary"
        className={classes.appBar}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {orgData === null ? 'New Organization' : 'Edit Organization'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <div className={classes.formContainer}>
        <Paper>
          <form
            className={classes.form}
            onSubmit={handleSubmit((org) => submit(org))}
          >
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              {orgData === null ? 'New Organization' : 'Edit Organization'}
            </Typography>

            <Controller
              name="name"
              control={control}
              defaultValue={orgData ? orgData.name : ''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Name"
                  labelPlacement="start"
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 100,
                  message: 'Must be less than 100 characters',
                },
              }}
            />
            <Button
              color="primary"
              style={{ marginTop: '16px' }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
          <StatusCodeResponse
            statusCode={statusCode}
            codeResponses={[
              {
                statusCode: 409,
                response: 'Duplicate Organization Found',
              },
            ]}
          />
        </Paper>
      </div>
    </div>
  );
}

export default OrgForm;
