import { useState, useEffect, useContext } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { UserContext } from '../context/UserStateManager';
import { OrgRole, SysRole } from '../Enums';
import { OrgModel } from '../orgs/Orgs';
import UserList from './UserList';

export interface UserModel {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  // password: string;
  role: SysRole;
  orgs: { org: OrgModel; devices: string[]; role: OrgRole }[];
  locked: boolean;
  hasAcceptedInvite: boolean;
}

export type UserToOrgDTO = {
  orgRole: OrgRole;
  devices: string[];
};

type Props = {
  admin?: boolean;
};

function Users({ admin }: Props) {
  const userCon = useContext(UserContext);
  const [users, setUsers] = useState<UserModel[]>([]);

  const getUsers = async () => {
    // if getting users for admin console, fetch from /users endpoint
    // otherwise this is for Org setttings so get users from /orgs/:id/users
    let res;
    if (admin) {
      res = await fetchLogoutOn401(
        userCon,
        process.env.REACT_APP_BACKEND_URL + '/users',
        {
          headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
        },
      );
    } else {
      res = await fetchLogoutOn401(
        userCon,
        process.env.REACT_APP_BACKEND_URL +
          '/orgs/' +
          userCon.state.currentOrg?.org._id +
          '/users',
        {
          headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
        },
      );
    }
    if (res.status === 200) {
      const users = await res.json();
      setUsers(users);
    }
    return res.status;
  };

  useEffect(() => {
    getUsers();
  }, [admin, userCon.state.currentOrg?.org._id, userCon.state.jwtToken]);

  const saveUser = async (userDTO: UserModel) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/users',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify(userDTO),
      },
    );
    getUsers();
    return res.status;
  };
  const newUserToOrg = async (email: string, userOrgDTO: UserToOrgDTO) => {
    const res = await fetchLogoutOn401(
      userCon,
      `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org._id}/users/${email}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify(userOrgDTO),
      },
    );

    getUsers();

    return res.status;
  };
  const existingUserToOrg = async (id: string, userOrgDTO: UserToOrgDTO) => {
    const res = await fetchLogoutOn401(
      userCon,
      `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org._id}/users/${id}`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify(userOrgDTO),
      },
    );

    getUsers();

    return res.status;
  };

  const updateUser = async (userDTO: UserModel) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/users/' + userDTO._id,
      {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify(userDTO),
      },
    );

    if (res.status === 200) {
      getUsers();
    }
    return res.status;
  };

  const removeUserFromOrg = async (user: UserModel, orgId: string) => {
    const url =
      process.env.REACT_APP_BACKEND_URL + `/orgs/${orgId}/users/${user._id}`;
    const res = await fetchLogoutOn401(userCon, url, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    });

    if (res.status === 200) {
      // Remove success
      setUsers(users.filter((u) => u._id !== user._id));
    }

    return res.status;
  };

  const deleteUser = async (user: UserModel) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/users/' + user._id,
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
      },
    );

    if (res.status === 204) {
      // Delete success
      // setUsers(users.filter((u) => u._id !== user._id));
      getUsers();
    }

    return res.status;
  };

  const resetUserPassword = async (user: UserModel) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/password',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user.email }),
      },
    );

    return res.status;
  };

  return (
    <UserList
      admin={admin}
      users={users}
      getUsers={getUsers}
      saveUser={admin ? saveUser : newUserToOrg}
      updateUser={admin ? updateUser : existingUserToOrg}
      deleteUser={admin ? deleteUser : removeUserFromOrg}
      resetUserPassword={resetUserPassword}
    />
  );
}

export default Users;
