import {
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import moment from 'moment';
import { useContext, useMemo, useRef, useState } from 'react';
import { tUserContext, UserContext } from '../context/UserStateManager';
import { ConditionType, ShowNameMap } from '../Enums';
import { DeviceSettingsContext } from './DevicePage';
import { DeviceStatus } from '../devices/Devices';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { InputCondition } from '../devices/DeviceSettingsObject';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Content: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    statusLog: {
      marginTop: 8,
      height: '89%',
    },
    StatusField: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '450px',
    },
    StatusBubble: {
      height: '20px',
      width: '20px',
      borderRadius: '20px',
    },
    StatusGood: {
      backgroundColor: '#11E300',
    },
    StatusBad: {
      backgroundColor: '#bebebe',
    },
  }),
);

type StatusDto = {
  time: string;
  source: string;
  type: string;
  description: string;
}[];
type StatusData = {
  index: number;
  time: moment.Moment;
  source: string;
  type: string;
  description: string;
}[];

export const fetchStatusJson = (userCon: tUserContext) => {
  if (userCon.state.currentDevice) {
    return fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
      '/devices/status/' +
      userCon.state.currentDevice._id,
      {
        headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      },
    ).then((res) => {
      if (res.ok) return res.json();
      else throw Error;
    });
  } else
    return new Promise(() => {
      throw Error;
    });
};

export default function DeviceStatusPage() {
  const classes = useStyles();
  const { configuration } = useContext(DeviceSettingsContext);
  const userCon = useContext(UserContext);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [status, setStatus] = useState<DeviceStatus | null>(null);
  const [liveStatus, setLiveStatus] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const listener = useRef<NodeJS.Timeout | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const device = userCon.state.currentDevice;
  const deviceConnected = useMemo(() => !!status, [status]);

  const [statusLog, setStatusLog] = useState<StatusData>([]);

  const inputName = useCallback(
    (input: number) => {
      for (let trigger of configuration.triggers) {
        if (trigger.condition.input === input) return trigger.name;
      }
      if (
        configuration.settings.systemEnable.conditionType ===
        ConditionType.INPUT_CONDITION &&
        (configuration.settings.systemEnable.condition as InputCondition)
          .input === input
      ) {
        return 'System';
      }
      if (
        configuration.settings.dimming.conditionType ===
        ConditionType.INPUT_CONDITION &&
        (configuration.settings.dimming.condition as InputCondition).input ===
        input
      ) {
        return 'Dimming';
      }
      if (
        configuration.settings.moodShowEnable.conditionType ===
        ConditionType.INPUT_CONDITION &&
        (configuration.settings.moodShowEnable.condition as InputCondition)
          .input === input
      ) {
        return 'Mood Lighting';
      }
      if (
        configuration.musicControl.musicShowEnable.conditionType ===
        ConditionType.INPUT_CONDITION &&
        (configuration.musicControl.musicShowEnable.condition as InputCondition)
          .input === input
      ) {
        return 'Music Control';
      }
      if (
        configuration.settings.maintenanceMode.conditionType ===
        ConditionType.INPUT_CONDITION &&
        (configuration.settings.maintenanceMode.condition as InputCondition)
          .input === input
      ) {
        return 'Maintenance';
      }
      return 'Input ' + input;
    },
    [configuration],
  );

  const handleFetchStatus = useCallback(() => {
    fetchStatusJson(userCon)
      .then((json) => {
        setStatus(json as DeviceStatus);
      })
      .catch((e) => {
        setStatus(null);
        if (listener.current) {
          clearInterval(listener.current);
          setLiveStatus(false);
        }
        console.log('Unable to connect to controller.');
      });
  }, [userCon]);

  const fetchErrors = useCallback(() => {
    fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
      '/devices/errors/' +
      userCon.state.currentDevice?._id,
      {
        headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      },
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error;
      })
      .then((json) =>
        setStatusLog(
          (json as StatusDto)
            .map((error, i) => ({
              index: i,
              time: moment(error.time),
              source: error.source,
              type: error.type,
              description: error.description,
            }))
            .reverse(),
        ),
      )
  }, [userCon.state.currentDevice?._id, userCon.state.jwtToken]);

  useEffect(() => {
    handleFetchStatus();
    fetchErrors();
    return () => {
      if (listener.current) clearInterval(listener.current);
      if (timeout.current) clearInterval(timeout.current);
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'time',
      headerName: 'Time',
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) =>
        moment(params.row.time)
          .tz(device?.timeZone ?? '')
          .format('L LTS'),
    },
    {
      field: 'source',
      headerName: 'Source',
      filterable: false,
      disableColumnMenu: true,
      width: 100,
    },
    {
      field: 'description',
      headerName: 'Message',
      width: 600,
    },
  ];

  if (!device) return <div></div>;
  return (
    <div className={classes.Content}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField}>
              <Typography variant="h3">Connection Status</Typography>
              <div
                className={
                  classes.StatusBubble +
                  ' ' +
                  (deviceConnected ? classes.StatusGood : classes.StatusBad)
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField} style={{ flexWrap: 'wrap' }}>
              <Typography variant="h3">Last Check-in: </Typography>
              <Typography style={{ marginLeft: 8 }}>
                {device.lastCheckin && device.timeZone
                  ? moment(device.lastCheckin)
                    .tz(device.timeZone)
                    .format('LLL z')
                  : 'Unknown'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField}>
              <Typography variant="h3">Event Active</Typography>
              <div
                className={
                  classes.StatusBubble +
                  ' ' +
                  (status && status.eventActive
                    ? classes.StatusGood
                    : classes.StatusBad)
                }
              />
            </div>
          </Grid>
          {status && status.eventActive && (
            <Grid item xs={12} md={6}>
              <div className={classes.StatusField}>
                <Typography variant="h3">Active Event Name: </Typography>
                <Typography>{status?.eventActive}</Typography>
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField}>
              <Typography variant="h3">System Enabled</Typography>
              <div
                className={
                  classes.StatusBubble +
                  ' ' +
                  (status && status.systemEnabled
                    ? classes.StatusGood
                    : classes.StatusBad)
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField}>
              <Typography variant="h3">Dimming Active</Typography>
              <div
                className={
                  classes.StatusBubble +
                  ' ' +
                  (status && status.dimmingActive
                    ? classes.StatusGood
                    : classes.StatusBad)
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField}>
              <Typography variant="h3">Mood Lighting Enabled</Typography>
              <div
                className={
                  classes.StatusBubble +
                  ' ' +
                  (status && status.moodLightingEnabled
                    ? classes.StatusGood
                    : classes.StatusBad)
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField}>
              <Typography variant="h3">Troubleshooting Active</Typography>
              <div
                className={
                  classes.StatusBubble +
                  ' ' +
                  (status && status.debugEnabled
                    ? classes.StatusGood
                    : classes.StatusBad)
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField}>
              <Typography variant="h3">Maintenance Lighting Active</Typography>
              <div
                className={
                  classes.StatusBubble +
                  ' ' +
                  (status && status.maintenanceLightingActive
                    ? classes.StatusGood
                    : classes.StatusBad)
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.StatusField}>
              <Typography variant="h3">Current Show</Typography>
              <Typography>
                {status && ShowNameMap[status.currentShow]}
              </Typography>
            </div>
          </Grid>
          {status?.hasOwnProperty('customerControlActive') && device.ccEnabled && (
            <Grid item xs={12} md={6}>
              <div className={classes.StatusField}>
                <Typography variant="h3">Customer Control Active</Typography>
                <div
                  className={
                    classes.StatusBubble +
                    ' ' +
                    (status && status.customerControlActive
                      ? classes.StatusGood
                      : classes.StatusBad)
                  }
                />
              </div>
            </Grid>
          )}
          {device.musicEnabled && (
            <Grid item xs={12} md={6}>
              <div className={classes.StatusField}>
                <Typography variant="h3">Music Control Enabled</Typography>
                <div
                  className={
                    classes.StatusBubble +
                    ' ' +
                    (status && status.musicControlEnabled
                      ? classes.StatusGood
                      : classes.StatusBad)
                  }
                />
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>
      {deviceConnected && (
        <Paper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3">Input Status</Typography>
            <FormControlLabel
              label="Live Inputs"
              labelPlacement="end"
              style={{ marginTop: '-8px' }}
              control={
                <Switch
                  checked={liveStatus}
                  color="primary"
                  onChange={() => {
                    if (!liveStatus) {
                      listener.current = setInterval(handleFetchStatus, 500);
                      timeout.current = setTimeout(() => {
                        setLiveStatus(false);
                        clearInterval(listener.current as NodeJS.Timeout);
                      }, 4 * 60 * 60 * 1000); // 4 hour timeout
                    } else {
                      clearInterval(listener.current as NodeJS.Timeout);
                    }
                    setLiveStatus(!liveStatus);
                  }}
                />
              }
            />
          </div>
          <br />
          <Grid container spacing={3}>
            {status &&
              status.inputStates.map((state, index) => (
                <Grid
                  item
                  xs={6}
                  md={2}
                  style={{ display: 'flex' }}
                  key={index}
                >
                  <div
                    className={
                      classes.StatusBubble +
                      ' ' +
                      (state ? classes.StatusGood : classes.StatusBad)
                    }
                  />
                  <div style={{ width: '8px' }} />
                  <Typography variant="h4">{inputName(index + 1)}</Typography>
                </Grid>
              ))}
          </Grid>
        </Paper>
      )}
      <Paper style={{ height: '510px' }}>
        <Typography variant="h3">Status Log</Typography>
        <Typography>
          {`Times in controller timezone: ${device.timeZone
            ? moment().tz(device.timeZone)?.format('z')
            : 'Unknown'
            }`}
        </Typography>
        <DataGrid
          className={classes.statusLog}
          pageSize={pageSize}
          onPageSizeChange={(newSize) => setPageSize(newSize.pageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          density="compact"
          rows={statusLog}
          columns={columns}
          getRowId={(row) => row.index}
          ref={(n) => {
            tableRef.current = n;
          }}
        />
      </Paper>
    </div>
  );
}
