import { useCallback, useContext, useEffect, useState } from 'react';
import useTitle from '../../hooks/useTitle.hook';
import { tUserContext, UserContext } from '../context/UserStateManager';
import AdminDeviceList from './AdminDeviceList';
import DeviceList from './DeviceList';
import DevicePage from '../configuration/DevicePage';
import DeviceSettings from './DeviceSettingsObject';
import { Redirect, Route } from 'react-router-dom';
import { ShowName } from '../Enums';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { Typography } from "@material-ui/core";

let abortController: AbortController;

let getDeviceError: boolean = false;

const getDevices = async (userCon: tUserContext) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  let res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL + '/devices',
    {
      headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      signal: abortController.signal,
    },
  );
  if (res.status === 200) {
    const devices = await res.json();
    return devices;
  }
  getDeviceError = true;
  return [];
};

const getOrgDevices = async (userCon: tUserContext) => {
  if (abortController) {
    console.log('cancelling request');
    abortController.abort();
  }
  abortController = new AbortController();
  let res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL +
    '/orgs/' +
    userCon.state.currentOrg?.org._id +
    '/devices',
    {
      headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      signal: abortController.signal,
    },
  );
  if (res.status === 200) {
    const devices = await res.json();

    //if an updated occurred in device settings, update the context since device settings uses that
    devices.forEach((device: DeviceModel) => {
      if (device._id === userCon.state.currentDevice?._id) {
        userCon.setState({ ...userCon.state, currentDevice: device });
      }
    });

    return devices;
  }
  getDeviceError = true;
  return [];
};

export interface DeviceStatus {
  currentShow: ShowName;
  timeLeft: number;
  inputStates: boolean[];
  previewActive: boolean;
  eventActive: string;
  systemEnabled: boolean;
  dimmingActive: boolean;
  moodLightingEnabled: boolean;
  musicControlEnabled: boolean;
  maintenanceLightingActive: boolean;
  customerControlActive?: boolean;
  debugEnabled: boolean;
}

export interface DeviceModel {
  _id: string;
  name: string;
  location?: string;
  timeZone: string;
  deviceID: string;
  agentURL: string;
  configuration: DeviceSettings;
  musicEnabled: boolean;
  ccEnabled: boolean;
  ccShortUrl: string;
  eventActive: string;
  lastCheckin: Date;
  org?: string;
}

export function Devices({ admin }: { admin?: boolean }) {
  const userCon = useContext(UserContext);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [displayData, setDisplayData] = useState(false);
  useTitle('Controllers');

  const refreshDevices = useCallback(async () => {
    getDeviceError = false;
    setDisplayData(false);
    setDevices([]);
    setDevices(await (admin ? getDevices(userCon) : getOrgDevices(userCon)));
    setDisplayData(true);
  }, [admin, userCon]);

  const getDevice = async (id?: string) => {
    if (!id) return;
    let res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/devices/' + id,
      {
        headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      },
    );

    if (res.ok) {
      return (await res.json()) as DeviceModel;
    }
  };

  const updateDevice = async (device: DeviceModel, deviceMongoID: string) => {
    let res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/devices/' + deviceMongoID,
      {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify(device),
      },
    );
    if (res.status === 200) {
      refreshDevices();
    }
    return res.status;
  };

  const deleteDevice = async (deviceMongoID: string) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/devices/' + deviceMongoID,
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
      },
    );

    if (res.status === 204) refreshDevices();

    return res.status;
  };

  useEffect(() => {
    refreshDevices();
  }, [admin, userCon.state.currentOrg?.org._id]);

  if (admin) {
    return (
      <AdminDeviceList
        devices={devices}
        getDevice={getDevice}
        updateDevice={updateDevice}
        deleteDevice={deleteDevice}
      />
    );
  } else {
    return (
      <div>
        <Route exact path="/controller">

          <div style={{ display: displayData ? 'block' : 'none' }}>
            <div style={{ display: getDeviceError ? 'block' : 'none' }}>
              <Typography variant="h2">Controllers</Typography>
              <br />
              <div>There was an error retrieving the controllers. Please click the "Controllers" link on the left navigation bar to load them.</div>
            </div>

            <div style={{ display: devices.length === 0 && !getDeviceError ? 'block' : 'none' }}>
              <Typography variant="h2">Controllers</Typography>
              <br />
              <div>No controllers have been found. Please click "Register Controller" on the left navigation bar to add a new controller.</div>
            </div>

            <div style={{ display: devices.length > 0 && !getDeviceError ? 'block' : 'none' }}>
              <DeviceList devices={devices} />
            </div>
          </div>

        </Route>
        <Route path="/controller/:pathID">
          {userCon.state.currentDevice ? (
            <DevicePage
              getDevices={refreshDevices}
              updateDevice={updateDevice}
            />
          ) : (
            <Redirect to={'/controller'} />
          )}
        </Route>
      </div>
    );
  }
}

export default Devices;
