import {
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useContext, useEffect, useRef } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import DeviceSettings, {
  SystemSettings,
} from '../devices/DeviceSettingsObject';
import { ConditionType, WhiteCeilingType, WhiteCeilingTypeMap } from '../Enums';
import ConditionSelection from './ConditionSelection';
import { DeviceSettingsContext, getUsedInputs } from './DevicePage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: '8px',
      backgroundColor: '#707070',
    },
    button: {
      margin: '16px auto 0 auto',
      height: 60,
    },
  }),
);

const checkInputNum = (dups: number[], newCond: any) =>
  dups.indexOf(newCond.condition?.input) !== -1;

const doesntNeedObj = (type: ConditionType) =>
  type === ConditionType.ALWAYS_OFF || type === ConditionType.ALWAYS_ON;

function trimConditionObjects(config: DeviceSettings) {
  if (doesntNeedObj(config.settings.dimming.conditionType)) {
    delete config.settings.dimming.condition;
  }
  if (doesntNeedObj(config.settings.maintenanceMode.conditionType)) {
    delete config.settings.maintenanceMode.condition;
  }
  if (doesntNeedObj(config.settings.moodShowEnable.conditionType)) {
    delete config.settings.moodShowEnable.condition;
  }
  if (doesntNeedObj(config.settings.systemEnable.conditionType)) {
    delete config.settings.systemEnable.condition;
  }
}

function checkForDuplicateInputs(
  formObject: UseFormReturn<SystemSettings>,
  newSettings: DeviceSettings,
) {
  let noErrors = true;
  const duplicates: number[] = [];
  getUsedInputs(newSettings).forEach((input, _, ar) => {
    if (ar.filter((i) => i === input).length > 1) duplicates.push(input);
  });
  if (checkInputNum(duplicates, newSettings.settings.dimming)) {
    noErrors = false;
    formObject.setError('dimming.condition.input', {
      type: 'InputInUse',
      message: 'Input Already in Use',
    });
  }
  if (checkInputNum(duplicates, newSettings.settings.maintenanceMode)) {
    noErrors = false;
    formObject.setError('maintenanceMode.condition.input', {
      type: 'InputInUse',
      message: 'Input Already in Use',
    });
  }
  if (checkInputNum(duplicates, newSettings.settings.moodShowEnable)) {
    noErrors = false;
    formObject.setError('moodShowEnable.condition.input', {
      type: 'InputInUse',
      message: 'Input Already in Use',
    });
  }
  if (checkInputNum(duplicates, newSettings.settings.systemEnable)) {
    noErrors = false;
    formObject.setError('systemEnable.condition.input', {
      type: 'InputInUse',
      message: 'Input Already in Use',
    });
  }
  return noErrors;
}

export default function SystemSettingsForm() {
  const classes = useStyles();
  const formObject = useForm<SystemSettings>();
  const formRef = useRef<HTMLFormElement>(null);
  const { configuration, setConfiguration, updating } = useContext(DeviceSettingsContext);

  useEffect(() => {
    const watch = formObject.watch((_, info) => {
      if (formRef.current && info.type) {
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true }),
        );
      }
    });
    return () => watch.unsubscribe();
  }, [formObject]);

  return (
    <form
      ref={formRef}
      style={{ display: 'flex', flexDirection: 'column' }}
      onSubmit={formObject.handleSubmit((formData) => {
        formObject.clearErrors();
        const newSettings = {
          ...configuration,
          settings: {
            ...configuration.settings,
            ...formData,
          },
        };
        newSettings.settings.dimming.brightness = parseInt(
          newSettings.settings.dimming.brightness as any,
        );
        newSettings.settings.maintenanceMode.brightness = parseInt(
          newSettings.settings.maintenanceMode.brightness as any,
        );
        trimConditionObjects(newSettings);
        if (checkForDuplicateInputs(formObject, newSettings))
          setConfiguration(newSettings);
      })}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">System Enable</Typography>
            <Typography variant="h4">
              Master function which controls the power to the lighting system.
              The system can be programmed to be always on or always off,
              activated by an input or activated during a selected time period.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <ConditionSelection<SystemSettings>
              formObject={formObject}
              defaultVal={{
                conditionType:
                  configuration.settings.systemEnable.conditionType,
                condition: configuration.settings.systemEnable.condition,
              }}
              basePath="systemEnable."
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Dimming</Typography>
            <Typography variant="h4">
              Using this setting allows you to dim the lights to reduce the
              brightness. This function can be programmed to be always on or
              always off, activated by an input or activated during a selected
              time period.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <ConditionSelection<SystemSettings>
              formObject={formObject}
              defaultVal={{
                conditionType: configuration.settings.dimming.conditionType,
                condition: configuration.settings.dimming.condition,
              }}
              basePath="dimming."
            />
            <Controller
              name="dimming.brightness"
              control={formObject.control}
              defaultValue={configuration.settings.dimming.brightness}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Brightness:"
                  labelPlacement="start"
                  control={
                    <FormControlLabel
                      label="%"
                      labelPlacement="end"
                      style={{ marginRight: 0 }}
                      control={
                        <TextField
                          style={{ width: '88px' }}
                          type="number"
                          disabled={updating}
                          value={value}
                          onBlur={(e) => { onChange(e.target.value) }}
                          onChange={(e) => { formObject.setValue("dimming.brightness", parseInt(e.target.value)) }}
                          fullWidth
                          error={!!error}
                          helperText={error ? error.message : null}
                          InputProps={{ inputProps: { min: 0, max: 100 } }}
                        />
                      }
                    />
                  }
                />
              )}
              rules={{
                validate: (value) => {
                  if (isNaN(parseInt(value))) return 'Value Required';
                  if (parseInt(value) < 0) return 'Must be 0 or greater';
                  if (parseInt(value) > 100) return 'Must be 100 or less';
                },
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Mood Lighting Enable</Typography>
            <Typography variant="h4">
              This setting activates the mood lighting show line-up designed to
              market your site to potential customers. This function can be
              programmed to be always on or always off, activated by an input or
              activated during a selected time period.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <ConditionSelection<SystemSettings>
              formObject={formObject}
              defaultVal={{
                conditionType:
                  configuration.settings.moodShowEnable.conditionType,
                condition: configuration.settings.moodShowEnable.condition,
              }}
              basePath="moodShowEnable."
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Maintenance Lighting</Typography>
            <Typography variant="h4">
              In maintenance mode the ceiling lights will output bright white
              light to optimize cleaning and maintenance. This function can be
              programmed to be always on or always off, activated by an input or
              activated during a selected time period.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <ConditionSelection<SystemSettings>
              formObject={formObject}
              defaultVal={{
                conditionType:
                  configuration.settings.maintenanceMode.conditionType,
                condition: configuration.settings.maintenanceMode.condition,
              }}
              basePath="maintenanceMode."
            />
            <Controller
              name="maintenanceMode.brightness"
              control={formObject.control}
              defaultValue={configuration.settings.maintenanceMode.brightness}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Brightness:"
                  labelPlacement="start"
                  control={
                    <FormControlLabel
                      label="%"
                      labelPlacement="end"
                      style={{ marginRight: 0 }}
                      control={
                        <TextField
                          style={{ width: '88px' }}
                          type="number"
                          disabled={updating}
                          value={value}
                          onBlur={(e) => {
                            onChange(e.target.value);
                          }}
                          onChange={(e) => formObject.setValue("maintenanceMode.brightness", parseInt(e.target.value))}
                          fullWidth
                          error={!!error}
                          helperText={error ? error.message : null}
                          InputProps={{ inputProps: { min: 0, max: 100 } }}
                        />
                      }
                    />
                  }
                />
              )}
              rules={{
                validate: (value) => {
                  if (isNaN(parseInt(value))) return 'Value Required';
                  if (parseInt(value) < 0) return 'Must be 0 or greater';
                  if (parseInt(value) > 100) return 'Must be 100 or less';
                },
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Default Ceiling Light Mode</Typography>
            <Typography variant="h4">
              When ceiling lights are not being utilized for a show, determine
              whether they are white or off.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <Controller
              name={'whiteCeilingBehavior'}
              control={formObject.control}
              defaultValue={configuration.settings.whiteCeilingBehavior}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Behavior:"
                  labelPlacement="start"
                  control={
                    <Select
                      disabled={updating}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    >
                      <MenuItem value={WhiteCeilingType.WHITE_OFF}>
                        {WhiteCeilingTypeMap[WhiteCeilingType.WHITE_OFF]}
                      </MenuItem>
                      <MenuItem value={WhiteCeilingType.WHITE_ON}>
                        {WhiteCeilingTypeMap[WhiteCeilingType.WHITE_ON]}
                      </MenuItem>
                    </Select>
                  }
                />
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
