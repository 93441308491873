import {
  AppBar,
  Button,
  createStyles,
  FormControlLabel,
  IconButton,
  Input,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Controller, useForm } from 'react-hook-form';
import StatusCodeResponse from '../common/StatusCodeResponse';
import { DeviceModel } from './Devices';
import { useContext, useState } from 'react';
import { UserContext } from '../context/UserStateManager';

type props = {
  deviceData: null | DeviceModel;
  submitDevice: Function;
  closeDialog: Function;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      height: `68px`,
      borderBottom: '1px solid #e0e0e0',
    },
    formRoot: {
      minHeight: '100%',
      backgroundColor: '#F4F7FC',
    },
    // necessary for content to be below app bar
    toolbar: { height: '68px' },
    formContainer: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      maxWidth: `min(500px, calc(100% - ${theme.spacing(2) * 2}px))`,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  }),
);

export function DeviceForm({ deviceData, submitDevice, closeDialog }: props) {
  const classes = useStyles();
  const userCon = useContext(UserContext);
  const { handleSubmit, control } = useForm<DeviceModel>();
  const [statusCode, setStatusCode] = useState(0);

  async function submit(device: DeviceModel) {
    // delete org field if not selected so server doesn't error on a mondoDB ID that is ''
    if (device.org === '') delete device.org;
    const status = await submitDevice(device, deviceData?._id);
    if (status === 200) {
      closeDialog();
      return;
    }
    setStatusCode(status);
  }

  return (
    <div className={classes.formRoot}>
      <AppBar
        color="secondary"
        className={classes.appBar}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">Edit Controller</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <div className={classes.formContainer}>
        <Paper>
          <form
            className={classes.form}
            onSubmit={handleSubmit((device) => submit(device))}
          >
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              Controller
            </Typography>

            <Controller
              name="name"
              control={control}
              defaultValue={deviceData?.name ?? ''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Name"
                  labelPlacement="start"
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 50,
                  message: 'Must be less than 50 characters',
                },
              }}
            />
            <Controller
              name="location"
              control={control}
              defaultValue={deviceData?.location ?? ''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Location"
                  labelPlacement="start"
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                maxLength: {
                  value: 50,
                  message: 'Must be less than 50 characters',
                },
              }}
            />
            <Controller
              name="org"
              control={control}
              defaultValue={deviceData?.org ? deviceData?.org : ''}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Organization"
                  labelPlacement="start"
                  control={
                    <Select
                      value={value}
                      onChange={onChange}
                      input={<Input />}
                      style={{ width: '200px' }}
                      renderValue={(selected: unknown) => {
                        let value = 'ORG NAME NOT FOUND ERROR';
                        for (let obj of userCon.state.user.orgs) {
                          if (obj.org._id === (selected as string)) {
                            value = obj.org.name;
                            break;
                          }
                        }
                        return value.length > 25
                          ? value.slice(0, 25) + '...'
                          : value;
                      }}
                    >
                      {userCon.state.user.orgs.map((element) => (
                        <MenuItem key={element.org._id} value={element.org._id}>
                          <ListItemText primary={element.org.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              )}
              rules={{ required: false }}
            />
            <Controller
              name="ccEnabled"
              control={control}
              defaultValue={deviceData?.ccEnabled || false}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Customer Control Enabled"
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={value}
                      color="primary"
                      onChange={onChange}
                    />
                  }
                />
              )}
              rules={{ required: false }}
            />

            <Controller
              name="musicEnabled"
              control={control}
              defaultValue={deviceData?.musicEnabled || false}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Music Control Enabled"
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={value}
                      color="primary"
                      onChange={onChange}
                    />
                  }
                />
              )}
              rules={{ required: false }}
            />
            <Button
              color="primary"
              style={{ margin: '16px auto 0 auto' }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
          <StatusCodeResponse
            statusCode={statusCode}
            codeResponses={[
              {
                statusCode: 409,
                response: '409 Response from server',
              },
            ]}
          />
        </Paper>
      </div>
    </div>
  );
}

export default DeviceForm;
