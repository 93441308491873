// unstable import for this issue: https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  createStyles,
  makeStyles,
  MuiThemeProvider,
  Theme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useContext } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import LoginPage from './components/login/LoginPage';
import { UserContext } from './components/context/UserStateManager';
import Admin from './components/admin/Admin';
import SetPassword from './components/login/SetPassword';
import NewUser from './components/login/NewUser';
import ForgotPasswordPage from './components/login/ForgotPasswordPage';
import OrgSettings from './components/orgs/OrgSettings';
import { OrgRole, SysRole } from './components/Enums';
import Devices from './components/devices/Devices';
import './globalStyles.css';
import UserProfile from './components/users/UserProfile';
import { QueryClient, QueryClientProvider } from 'react-query';
import RegisterController from './components/devices/RegisterController';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Nunito Sans',
    fontSize: 14,
  },
  palette: {
    primary: { main: '#0380BA' },
    secondary: { main: '#ffffff' },
    background: { default: '#f4f7fc', paper: '#ffffff' },
    text: { primary: '#171725' },
  },
  overrides: {
    MuiFormControlLabel: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      labelPlacementStart: {
        color: '#5e5d5d',
        marginLeft: '0',
        marginRight: '0',
        margin: '8px 0',
      },
    },
    MuiPaper: {
      root: {},
      elevation1: {
        padding: 16,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          // backgroundColor: 'rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgba(3, 128, 186, 0.1)',
        },
      },
    },
  },
});
theme.typography.h1 = {
  fontWeight: 800,
  fontSize: '24px',
  [theme.breakpoints.up('md')]: {
    fontSize: '28px',
  },
};
theme.typography.h2 = {
  fontWeight: 400,
  fontSize: '20px',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
};
theme.typography.h3 = {
  fontWeight: 600,
  fontSize: '16px',
};
theme.typography.h4 = {
  fontWeight: 400,
  fontSize: '14px',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      overflowX: 'hidden',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      maxWidth: `calc(100% - ${theme.spacing(3) * 2}px)`,
    },
  }),
);

const queryClient = new QueryClient();

function App() {
  const userCon = useContext(UserContext);
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route
                exact
                path={['/login', '/login/:orgId']}
                component={LoginPage}
              />
              <Route
                exact
                path="/forgotpassword"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/password/:email/:token"
                component={() => SetPassword(false)}
              />
              <Route
                exact
                path="/invite/:email/:token"
                component={() => SetPassword(true)}
              />
              <Route
                exact
                path="/newuser"
                component={() => NewUser()}
              />
              <Route>
                {userCon.state.jwtToken !== '' ? (
                  <div className={classes.root}>
                    <Navigation />
                    <main className={classes.content}>
                      <div className={classes.toolbar} />
                      <Switch>
                        <Route path="/admin">
                          {userCon.state.user.role === SysRole.SYSTEM_USER ? (
                            <Redirect to={'/controller'} />
                          ) : (
                            <Admin />
                          )}
                        </Route>
                        <Route path="/controller" component={Devices} />
                        <Route path="/register" component={RegisterController} />
                        <Route path="/organization">
                          {userCon.state.user.role !== SysRole.SYSTEM_USER ||
                            userCon.state.currentOrg?.role ===
                            OrgRole.ORG_ADMIN ? (
                            <OrgSettings />
                          ) : (
                            <Redirect to={'/controller'} />
                          )}
                        </Route>
                        <Route path="/user" component={UserProfile} />
                        <Redirect to={'/controller'} />
                      </Switch>
                    </main>
                  </div>
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
            </Switch>
          </Router>
        </QueryClientProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App;
