import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useEffect, useState } from 'react';
import { CirclePicker } from 'react-color';
import tinycolor from 'tinycolor2';
import { COLORS } from '../Enums';

export function textColorFromBg(hex: string) {
  return tinycolor(hex).isDark() ? '#f4f7fc' : '#171725';
}

export const WHITE = [255, 227, 179] as const;

export function mapColorValues(value: number[][]) {
  if (value === undefined)
    return [
      {
        name: 'White',
        display: '#ffffff',
        value: [...WHITE],
      },
    ];
  return value.map(
    (val) =>
      COLORS.find(
        (c) =>
          c.value[0] === val[0] &&
          c.value[1] === val[1] &&
          c.value[2] === val[2],
      ) ?? {
        name: 'White',
        display: '#ffffff',
        value: [...WHITE],
      },
  );
}

type props = {
  minColors: number;
  maxColors: number;
  value: [number, number, number][];
  onChange: (...event: any[]) => void;
  blockSubmit: (arg0: boolean) => void;
  outsideEvent: null | any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorRoot: {
      marginTop: theme.spacing(1),
    },
    pickerPaper: {
      position: 'absolute',
      top: '75%',
      left: 0,
      zIndex: 9,
      padding: theme.spacing(2),
      borderRadius: '16px',
      backgroundColor: '#d4d4d4',
    },
  }),
);

export default function MultiColorPicker({
  minColors,
  maxColors,
  value,
  onChange,
  blockSubmit,
  outsideEvent,
}: props) {
  const classes = useStyles();
  const [pickerState, setPickerState] = useState({
    open: false,
    index: -1,
    color: [] as number[],
  });
  const data = mapColorValues(value);

  useEffect(() => {
    if (data.length > maxColors) {
      onChange(data.slice(0, maxColors).map((color) => color.value));
    }
    if (data.length < minColors) {
      let t = [...data.map((color) => color.value)];
      for (let i = 0; i < minColors - t.length; i++) {
        t.push([...WHITE]);
      }
      onChange(t);
    }
  }, [maxColors, minColors]);

  useEffect(() => {
    blockSubmit(pickerState.open);
  }, [pickerState.open]);

  useEffect(() => {
    if (outsideEvent && pickerState.open) {
      setPickerState({ ...pickerState, open: false });
    }
  }, [outsideEvent]);

  return (
    <div className={classes.colorRoot}>
      {data.length > 0 && (
        <Typography
          variant="h3"
          style={{ fontWeight: 400, margin: '16px 0 8px 0' }}
        >
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              Colors:
            </div>
            {data.length < maxColors && (
              <Button
                variant="text"
                onClick={() =>
                  onChange([...data.map((color) => color.value), WHITE])
                }
                style={{ marginLeft: 'auto', fontSize: '30px', padding: "0px" }}
              >
                +
              </Button>
            )}
          </div>
        </Typography>
      )}
      <Table>
        <TableBody>
          {data.map((color, index) => (
            <TableRow
              key={index}
              style={{
                backgroundColor: color.display,
                border: '1px solid',
                borderColor: '#171725',
              }}
            >
              <TableCell
                style={{
                  position: 'relative',
                  color: textColorFromBg(color.display),
                  borderColor: '#171725',
                }}
                onClick={(e) => {
                  if (!pickerState.open) {
                    setPickerState({ open: true, index, color: color.value });
                    e.stopPropagation();
                  }
                }}
              >
                {color.name}

                {pickerState.open && pickerState.index === index && (
                  <Paper
                    className={classes.pickerPaper}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                      // stop form submit and close picker
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setPickerState({ ...pickerState, open: false });
                      }
                    }}
                  >
                    <CirclePicker
                      color={mapColorValues([pickerState.color])[0].display}
                      colors={COLORS.map((color) => color.display)}
                      onChangeComplete={(pick, e) => {
                        const pickedValue = COLORS.find(
                          (c) =>
                            c.display.toLowerCase() === pick.hex.toLowerCase(),
                        )?.value ?? [...WHITE];
                        onChange(
                          data.map((color, i) =>
                            i === index ? pickedValue : color.value,
                          ),
                        );
                        setPickerState({
                          open: false,
                          index,
                          color: pickedValue,
                        });
                      }}
                    />
                  </Paper>
                )}
              </TableCell>
              <TableCell
                align="right"
                style={{ width: '24px', borderColor: '#171725' }}
              >
                {index > 0 && (
                  <IconButton
                    style={{
                      padding: '0',
                      margin: '0 4px 0 auto',
                      fontSize: '36px',
                      color: textColorFromBg(color.display),
                    }}
                    onClick={() => {
                      [data[index], data[index - 1]] = [
                        data[index - 1],
                        data[index],
                      ];
                      onChange(data.map((color) => color.value));
                    }}
                  >
                    <ArrowUpwardOutlined />
                  </IconButton>
                )}
                {index < data.length - 1 && (
                  <IconButton
                    style={{
                      padding: '0',
                      margin: '0 4px 0 auto',
                      fontSize: '36px',
                      color: textColorFromBg(color.display),
                    }}
                    onClick={() => {
                      [data[index], data[index + 1]] = [
                        data[index + 1],
                        data[index],
                      ];
                      onChange(data.map((color) => color.value));
                    }}
                  >
                    <ArrowDownwardOutlined />
                  </IconButton>
                )}
              </TableCell>
              <TableCell
                align="right"
                style={{ width: '24px', borderColor: '#171725' }}
              >
                {index >= minColors && (
                  <IconButton
                    style={{
                      padding: '0',
                      margin: '0 4px 0 auto',
                      fontSize: '36px',
                      color: textColorFromBg(color.display),
                    }}
                    onClick={() =>
                      onChange(
                        data
                          .filter((_, i) => i !== index)
                          .map((color) => color.value),
                      )
                    }
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
