import {
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useContext, useState } from 'react';
import {
  defaultTriggerSettings,
  TriggerSettings,
} from '../devices/DeviceSettingsObject';
import TriggerForm from './TriggerForm';
import { DeviceSettingsContext } from './DevicePage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newTrigger: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '32px 0',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        '& > button': {
          margin: '16px auto 0 auto',
        },
      },
    },
    inputNum: {
      justifyContent: 'center',
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    },
  }),
);

export default function TriggerList() {
  const classes = useStyles();
  const { configuration, setConfiguration, usedInputs, updating } = useContext(
    DeviceSettingsContext,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogState, setDialogState] = useState({
    open: false,
    triggerData: null as null | TriggerSettings,
    row: -1,
  });
  const closeDialog = () =>
    setDialogState({ open: false, triggerData: null, row: -1 });
  const firstUnusedInput = Array.from(
    { length: configuration.settings.triggerCount },
    (_, i) => i + 1,
  ).find((i) => usedInputs.indexOf(i) === -1);

  return (
    <Paper>
      <Typography variant="h3">Input Information</Typography>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Input #</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Zone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {configuration.triggers
              .sort((a, b) => a.condition.input - b.condition.input)
              .map((trigger, index) => (
                <TableRow key={index}>
                  <TableCell width={1}>
                    <IconButton
                      disabled={updating}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setDialogState({
                          ...dialogState,
                          triggerData: trigger,
                          row: index,
                        });
                      }}
                      style={{ padding: 0 }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell width={50} style={{ textAlign: 'center' }}>
                    {trigger.condition.input}
                  </TableCell>
                  <TableCell>{trigger.name}</TableCell>
                  <TableCell>
                    {configuration.settings.zones[trigger.zone]?.name ??
                      'No Zones Found'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className={classes.newTrigger}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              !!firstUnusedInput &&
              setDialogState({
                open: true,
                triggerData: {
                  ...defaultTriggerSettings,
                  condition: {
                    ...defaultTriggerSettings.condition,
                    input: firstUnusedInput,
                  },
                },
                row: -1,
              })
            }
            disabled={!firstUnusedInput || updating}
          >
            Add a Trigger
          </Button>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setDialogState({ ...dialogState, open: true });
          }}
        >
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem
          color="error"
          onClick={() => {
            setAnchorEl(null);
            setConfiguration({
              ...configuration,
              triggers: configuration.triggers.filter((trigger, index) => {
                return dialogState.row !== index;
              }),
            });
          }}
        >
          <Typography color="error">Remove</Typography>
        </MenuItem>
      </Menu>
      <Dialog fullScreen open={dialogState.open} onClose={closeDialog}>
        {dialogState.triggerData && (
          <TriggerForm
            closeDialog={closeDialog}
            triggerData={dialogState.triggerData}
            configuration={configuration}
            submit={(trigger) => {
              if (dialogState.row === -1) {
                setConfiguration({
                  ...configuration,
                  triggers: [...configuration.triggers, trigger],
                });
              } else {
                setConfiguration({
                  ...configuration,
                  triggers: configuration.triggers.map((oldTrigger, index) =>
                    dialogState.row === index ? trigger : oldTrigger,
                  ),
                });
              }
            }}
          />
        )}
      </Dialog>
    </Paper>
  );
}
