import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import colorwashLogo from '../../assets/images/color-wash-logo-vertical.png';
import useTitle from '../../hooks/useTitle.hook';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginPage: {
      margin: 'calc(33vh - 190px) auto',
      width: '312px',
      display: 'flex',
      flexDirection: 'column',
    },

    colorwashlogo: {
      width: '312px',
    },
    loginPaper: {
      marginTop: theme.spacing(4),
      '& form': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& button': {
          margin: '0 auto',
          width: 'fit-content',
        },
        '& h1': {
          fontSize: '18px',
          fontWeight: 'bold',
        },
      },
    },
    checkForgotLine: {
      padding: '0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#818387',
    },
    formController: { marginBottom: theme.spacing(2) },
  }),
);

type tNewAcc = {
  email: string;
};

export const NewUser = () => {
  const history = useHistory();
  const classes = useStyles();
  const { handleSubmit, control, setError } = useForm<tNewAcc>();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const timeLimit = 5000;

  useTitle('New Account Setup');

  const showToastMessage = () => {
    toast.success(
      'Please follow the link sent to your email to finish setting up your account.',
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: timeLimit,
      },
    );
  };

  const showToastError = () => {
    toast.error('An error occurred when sending the confirmation email. Please contact G&G Industrial Lighting for assistance.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: timeLimit,
    });
  }

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const submit = async (userLogin: tNewAcc) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const regexEmailResult = emailRegex.exec(userLogin.email);

    if (regexEmailResult === null) {
      setError('email', {
        type: 'pattern',
        message: 'Email format is incorrect.',
      });
      return;
    }

    const userDTO = JSON.stringify({
      email: userLogin.email.toLowerCase()
    });

    let resp = await fetch(process.env.REACT_APP_BACKEND_URL + '/newuser', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: userDTO,
    });

    if (resp.status === 409) {
      setError('email', {
        type: 'validate',
        message: 'Email already exists',
      });
      return;
    }

    setDisableSubmit(true);

    if (resp.status === 500) {
      showToastError();
    } else {
      showToastMessage();
    }

    await delay(timeLimit);

    history.push('/login');
  };

  return (
    <div className={classes.loginPage}>
      <img
        className={classes.colorwashlogo}
        src={colorwashLogo}
        alt="Colorwash"
      />
      <Paper className={classes.loginPaper}>
        <form onSubmit={handleSubmit((userLogin) => submit(userLogin))}>
          <Typography variant="h1">New Account Setup</Typography>

          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                className={classes.formController}
                label="Email"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Required',
              maxLength: {
                value: 320,
                message: 'Must be less than 320 characters',
              },
            }}
          />

          <Button
            color="primary"
            disabled={disableSubmit}
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </form>
      </Paper>
      <ToastContainer />
    </div>
  );
};

export default NewUser;
