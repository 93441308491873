import {
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import { useContext, useMemo, useState } from 'react';
import { UserContext } from '../context/UserStateManager';
import { DeviceSettingsContext } from './DevicePage';
//import { ShowConfiguration } from '../devices/DeviceSettingsObject';
import { LineupType } from '../Enums'
import { ShowLineup } from '../admin/SystemLineupList';

type props = {
  lineupType: string;
  open: boolean;
  onClose: (lineup: any[] | null) => void;
  systemLineups: ShowLineup[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      margin: theme.spacing(2),
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '20vh auto',
      width: 'fit-content',
      minWidth: '250px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

export default function LoadLineupModal({ lineupType, open, onClose, systemLineups }: props) {
  const classes = useStyles();
  const userCon = useContext(UserContext);
  const [selectedLineup, setSelectedLineup] = useState(0);
  const [confirm, setConfirm] = useState(false);

  let lineup = (lineupType === LineupType.MOOD_LIGHTING) ?
    (userCon.state.currentOrg?.org.orgLineups ?? []).concat(systemLineups || [])
    : (userCon.state.currentOrg?.org.orgMusicLineups ?? [])

  const availableLineups = useMemo(
    () => lineup,
    [userCon, systemLineups],
  );

  const modalClose = (lineup: any[] | null) => {
    setConfirm(false);
    onClose(lineup);
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => modalClose(null)}
    >
      <Paper className={classes.paper}>
        <Typography variant="h2">Open Show Lineup</Typography>
        {!confirm && (
          <>
            <FormControlLabel
              label="Select Lineup:"
              labelPlacement="start"
              control={
                <FormControl style={{ marginLeft: '8px' }}>
                  <Select
                    value={selectedLineup}
                    onChange={(e) =>
                      setSelectedLineup(e.target.value as number)
                    }
                  >
                    {availableLineups.map(
                      (
                        obj: {
                          name: string;
                          lineup: any[];
                          scope?: string;
                        },
                        i,
                      ) => (
                        <MenuItem key={i} value={i}>
                          {obj?.scope === 'SYSTEM' && <b>System -&nbsp;</b>}
                          {obj.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              }
            />
            <div className={classes.buttons}>
              <Button
                variant="text"
                color="secondary"
                style={{ marginRight: '16px', color: '#818387' }}
                onClick={() => modalClose(null)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setConfirm(true)}
              >
                Open
              </Button>
            </div>
          </>
        )}
        {confirm && (
          <>
            <Typography variant="subtitle2">
              Are you sure you want to open this lineup?
            </Typography>
            <Typography variant="subtitle2">
              This will replace your current lineup.
            </Typography>
            <div className={classes.buttons}>
              <Button
                variant="text"
                color="secondary"
                style={{ marginRight: '16px', color: '#818387' }}
                onClick={() => modalClose(null)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  modalClose(availableLineups[selectedLineup].lineup)
                }
              >
                Replace
              </Button>
            </div>
          </>
        )}
      </Paper>
    </Modal>
  );
}
