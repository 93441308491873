import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import colorwashLogo from '../../assets/images/color-wash-logo-vertical.png';
import useTitle from '../../hooks/useTitle.hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginPage: {
      margin: 'calc(40vh - 190px) auto',
      width: '312px',
      display: 'flex',
      flexDirection: 'column',
    },
    colorwashlogo: {
      width: '312px',
    },
    loginPaper: {
      marginTop: theme.spacing(4),
      '& form': {
        display: 'flex',
        height: '145px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& button': {
          // margin: '0 auto',
          width: 'fit-content',
        },
        '& h1': {
          fontSize: '18px',
          fontWeight: 'bold',
        },
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  }),
);

type tUserEmail = { email: string };

export const ForgotPasswordPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { handleSubmit, control } = useForm<tUserEmail>();
  useTitle('Reset Password');

  const submit = async (userEmail: tUserEmail) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userEmail),
    });

    history.push('/login');
  };

  return (
    <div className={classes.loginPage}>
      <img
        className={classes.colorwashlogo}
        src={colorwashLogo}
        alt="Colorwash"
      />
      <Paper className={classes.loginPaper}>
        <form onSubmit={handleSubmit((userSubmit) => submit(userSubmit))}>
          <Typography variant="h1">Reset Password</Typography>

          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email Address"
                type="email"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Required',
              maxLength: {
                value: 320,
                message: 'Must be less than 320 characters',
              },
            }}
          />

          <div className={classes.buttons}>
            {/* <Link to="/">
              <Button color="primary" variant="contained">
                Go Back
              </Button>
            </Link> */}

            <Button color="primary" type="submit" variant="contained">
              Continue
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default ForgotPasswordPage;
