import {
  Button,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { refreshUserContext, UserContext } from '../context/UserStateManager';
import { DeviceSettingsContext } from './DevicePage';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { CustomerControlShowConfiguration } from '../customer-control/CustomerControlShowLineup';
import { CustomerControlSettings } from '../devices/DeviceSettingsObject';
import { QRCodeCanvas } from 'qrcode.react';
import ConfirmationModal from '../common/ConfirmationModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginTop: '8px',
      backgroundColor: '#707070',
    },
    modalPaper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10vh auto',
      width: '60%',
      maxHeight: '60%',
    },
    jsonContent: {
      overflowY: 'scroll',
      margin: theme.spacing(2),
    },
    inputNum: {
      justifyContent: 'center',
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    },
  }),
);

type props = {
  setCustomerControlSettings: (settings: CustomerControlSettings) => void;
};

interface CustomerControlShowConfigurationWithId
  extends CustomerControlShowConfiguration {
  _id: string;
}

export default function CustomerControlSettingsForm({
  setCustomerControlSettings,
}: props) {
  const classes = useStyles();
  const userCon = useContext(UserContext);
  const { configuration, updating } = useContext(DeviceSettingsContext);
  const [availableShows, setAvailableShows] =
    useState<CustomerControlShowConfigurationWithId[]>();
  const [selectedShows, setSelectedShows] = useState<string[]>([]);
  const [generateURlModalOpen, setGenerateURLModalOpen] = useState(false);
  const currentShortUrl = userCon.state.currentDevice?.ccShortUrl;

  useEffect(() => {
    fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + `/customer-control/dashboard/shows`,
      {
        headers: {
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        method: 'GET',
      },
    )
      .then((res) => res.json())
      .then((shows) => setAvailableShows(shows));
  }, []);

  useEffect(() => {
    setSelectedShows(
      configuration?.customerControl?.enabledShows?.filter((id) =>
        availableShows?.find((show) => show._id === id),
      ) || [],
    );
  }, [configuration, availableShows]);

  const handleShowChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedShows(value);
  };

  const handleShowSubmit = () => {
    if (!availableShows) return;
    const shows = selectedShows;
    setCustomerControlSettings({
      ...configuration.customerControl,
      enabledShows: shows,
    });
  };

  const generateId = async () => {
    const currentDevice = userCon.state.currentDevice;
    if (!currentDevice) return;
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
        `/customer-control/dashboard/${currentDevice._id}/shortUrl/generate`,
      {
        headers: {
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        method: 'GET',
      },
    );
    if (res.ok) {
      refreshUserContext(userCon.state, userCon.setState);
    }
    setGenerateURLModalOpen(false);
  };

  return (
    <div>
      <Grid container spacing={3} style={{ marginBottom: 16 }}>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Customer Control Enable</Typography>
            <Typography variant="h4">
              Controls whether or not the Customer Control feature is enabled
              for this controller.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <FormControlLabel
              label="Enabled"
              labelPlacement="start"
              className={classes.inputNum}
              control={
                <Switch
                  disabled={updating}
                  checked={configuration?.customerControl?.active || false}
                  color="primary"
                  onChange={(e) =>
                    setCustomerControlSettings({
                      ...configuration.customerControl,
                      active: e.target.checked,
                    })
                  }
                />
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Inputs</Typography>
            {/* <Typography variant="h4">
              Input trigger used to signal the start of a cycle. This will pass
              control of the show selection to the next customer (or to default
              lineup).
            </Typography> */}
            <Divider classes={{ root: classes.divider }} />
            <FormControlLabel
              label="Start Cycle Input:"
              labelPlacement="start"
              className={classes.inputNum}
              control={
                <Select
                  style={{ marginLeft: '8px' }}
                  value={configuration?.customerControl?.inputs?.start || 0}
                  disabled={updating}
                  onChange={(e) =>
                    setCustomerControlSettings({
                      ...configuration.customerControl,
                      inputs: {
                        ...configuration.customerControl.inputs,
                        start: e.target.value as number,
                      },
                    })
                  }
                >
                  <MenuItem key={'none'} value={0}>
                    None
                  </MenuItem>
                  {Array.from(
                    Array(configuration.settings.triggerCount).keys(),
                  ).map((val) => (
                    <MenuItem key={val} value={val + 1}>
                      {`Input ${val + 1}`}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
            <FormControlLabel
              label="Priority Input:"
              labelPlacement="start"
              className={classes.inputNum}
              control={
                <Select
                  multiple
                  disabled={updating}
                  placeholder="None"
                  style={{ width: '150px', marginLeft: '10px' }}
                  value={
                    Array.isArray(
                      configuration?.customerControl?.inputs?.priority,
                    )
                      ? configuration.customerControl.inputs.priority
                      : []
                  }
                  onChange={(e) => {
                    console.log(e.target.value);
                    setCustomerControlSettings({
                      ...configuration.customerControl,
                      inputs: {
                        ...configuration.customerControl.inputs,
                        priority: e.target.value as number[],
                      },
                    });
                  }}
                  renderValue={(values: any) => (
                    <span>
                      {values.map((val: number) => `Input ${val}`).join(', ')}
                    </span>
                  )}
                >
                  {Array.from(
                    Array(configuration.settings.triggerCount).keys(),
                  ).map((val) => (
                    <MenuItem key={val} value={val + 1}>
                      {`Input ${val + 1}`}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Enabled Shows</Typography>
            <Typography variant="h4">
              Control which shows from the available show list are able to be
              selected by customers.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <FormControlLabel
              label="Shows: "
              labelPlacement="start"
              className={classes.inputNum}
              control={
                <Select
                  multiple
                  disabled={updating}
                  value={selectedShows}
                  input={<Input />}
                  style={{ width: '200px', marginLeft: '10px' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                  }}
                  onChange={handleShowChange}
                  onClose={handleShowSubmit}
                  renderValue={(value: any) => (
                    <span>
                      {value
                        .map(
                          (val: any) =>
                            availableShows?.find((show) => show._id === val)
                              ?.name,
                        )
                        .join(', ')}
                    </span>
                  )}
                >
                  {availableShows?.map((show) => (
                    <MenuItem key={show._id} value={show._id}>
                      <ListItemText primary={show.name} />
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Force Cycle</Typography>
            <Typography variant="h4">
              Send cycle start signal to backend.
            </Typography>
            <Divider classes={{ root: classes.divider }} />

            <div style={{ display: 'flex' }}>
              <Button
                disabled={updating}
                className={classes.button}
                component="label"
                color="primary"
                variant="contained"
                style={{
                  marginTop: '10px',
                }}
                onClick={() => {
                  fetchLogoutOn401(
                    userCon,
                    process.env.REACT_APP_BACKEND_URL +
                      `/customer-control/dashboard/${userCon.state.currentDevice?._id}/force-cycle`,
                    {
                      headers: {
                        Authorization: 'Bearer ' + userCon.state.jwtToken,
                      },
                      method: 'POST',
                    },
                  );
                }}
              >
                Force Cycle
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Device Short ID {'&'} URL</Typography>
            <Divider classes={{ root: classes.divider }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
              }}
            >
              <Typography>
                Device Short ID: <b>{currentShortUrl ?? '<none>'}</b>
              </Typography>
              <Typography>
                Customer Control App URL:{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_CC_APP_URL}/${
                    currentShortUrl ?? ''
                  }`}
                >{`${process.env.REACT_APP_CC_APP_URL}/${
                  currentShortUrl ?? ''
                }`}</a>
              </Typography>
              <Button
                disabled={updating}
                className={classes.button}
                component="label"
                variant="contained"
                style={{
                  margin: '16px auto 0px auto',
                  backgroundColor: '#F0142F',
                  color: '#ffffff',
                }}
                onClick={() => setGenerateURLModalOpen(true)}
              >
                Generate ID
              </Button>
              <ConfirmationModal
                open={generateURlModalOpen}
                onClose={() => setGenerateURLModalOpen(false)}
                onConfirm={generateId}
                title="Generate ID?"
                message="Are you sure you want to generate a new ID? This will disable the previous URL and QR Code!"
                confirmText="Generate"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h3">Device QR Code</Typography>
            <Divider classes={{ root: classes.divider }} />
            {currentShortUrl ? (
              <>
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <div
                    style={{ margin: 'auto', width: '200px', height: '200px' }}
                  >
                    <QRCodeCanvas
                      id="qr-code"
                      value={
                        userCon.state.currentDevice?.ccShortUrl
                          ? `${process.env.REACT_APP_CC_APP_URL}/${currentShortUrl}`
                          : ''
                      }
                      size={200}
                      level="H"
                      includeMargin={false}
                      style={{ position: 'absolute' }}
                    />
                    <QRCodeCanvas
                      id="qr-code-hidden"
                      value={
                        userCon.state.currentDevice?.ccShortUrl
                          ? `${process.env.REACT_APP_CC_APP_URL}/${currentShortUrl}`
                          : ''
                      }
                      size={2048}
                      hidden
                      level="H"
                      includeMargin={false}
                      style={{ position: 'absolute' }}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <Button
                    disabled={updating}
                    className={classes.button}
                    component="label"
                    color="primary"
                    variant="contained"
                    style={{
                      marginTop: '10px',
                    }}
                    onClick={() => {
                      const canvas: any =
                        document.getElementById('qr-code-hidden');
                      if (canvas === null) return;

                      const pngUrl = canvas
                        .toDataURL('image/png')
                        .replace('image/png', 'image/octet-stream');

                      const downloadLink = document.createElement('a');
                      downloadLink.href = pngUrl;
                      downloadLink.download = `colorwash-qr-${userCon.state.currentDevice?.ccShortUrl}.png`;
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }}
                  >
                    Download QR
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <Typography>
                  Please generate a Short Id for this device.
                </Typography>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
