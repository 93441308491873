import { AppBar, createStyles, IconButton, makeStyles, Paper, Theme, Toolbar, Typography, } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DeviceModel } from './Devices';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../context/UserStateManager';
import moment from "moment/moment";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { fetchLogoutOn401 } from "../common/Handle401Fetch";
import { LogType, LogTypeMap } from "../Enums";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Content: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    deviceLog: {
      marginTop: 8,
      height: '89%',
    },
    appBar: {
      height: `68px`,
      borderBottom: '1px solid #e0e0e0',
    },
    formRoot: {
      minHeight: '100%',
      backgroundColor: '#F4F7FC',
    },
    // necessary for content to be below app bar
    toolbar: { height: '68px' },
    formContainer: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      maxWidth: `min(500px, calc(100% - ${theme.spacing(2) * 2}px))`,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  }),
);

type LogDto = {
  time: string;
  source: string;
  type: string;
  description: string;
  tokenIssued: string;
  revokeCode: string;
  tokenLifespan: string;
  showChanges: [];
}[];

type LogData = {
  index: number;
  time: moment.Moment;
  source: string;
  type: string;
  description: string;
  tokenIssued: string;
  revokeCode: string;
  tokenLifespan: string;
  showChanges: string
}[];


type props = {
  deviceData: null | DeviceModel;
  closeDialog: Function;
  logType: LogType | null;
};

export function DeviceLog({ deviceData, closeDialog, logType }: props) {
  const classes = useStyles();
  const userCon = useContext(UserContext);
  const listener = useRef<NodeJS.Timeout | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [pageSize, setPageSize] = useState(25);
  const [deviceLog, setDeviceLog] = useState<LogData>([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [col, setCol] = useState<GridColDef[]>([])

  const columns: GridColDef[] = [
    {
      field: 'time',
      headerName: 'Time',
      width: 200,
      filterable: true,
      disableColumnMenu: true,
      valueGetter: (params) =>
        moment(params.row.time)
          .tz(deviceData?.timeZone ?? '')
          .format('L LTS'),
    },
    {
      field: 'source',
      headerName: 'Source',
      filterable: true,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: 'type',
      headerName: 'Type',
      filterable: true,
      width: 120,
    },
    {
      field: 'description',
      headerName: 'Message',
      filterable: true,
      width: 600,
    },
  ];

  const settingsColumns: GridColDef[] = [
    {
      field: 'time',
      headerName: 'Time',
      width: 200,
      filterable: true,
      disableColumnMenu: true,
      valueGetter: (params) =>
        moment(params.row.time)
          .tz(deviceData?.timeZone ?? '')
          .format('L LTS'),
    },
  ];

  const ccColumns = [
    {
      field: 'tokenIssued',
      headerName: 'Time',
      width: 200,
      filterable: true,
      disableColumnMenu: true,
      valueGetter: (params: any) =>
        moment(params.row.tokenIssued)
          .tz(deviceData?.timeZone ?? '')
          .format('L LTS'),
    },
    {
      field: 'revokeCode',
      headerName: 'Revoke Code',
      filterable: true,
      disableColumnMenu: true,
      width: 150,
    },
    {
      field: 'tokenLifespan',
      headerName: 'Token Duration (s)',
      filterable: true,
      width: 200,
    },
    {
      field: 'showChanges',
      headerName: 'Show Count Change',
      filterable: true,
      width: 600,
    },

  ];

  useEffect(() => {

    if (logType === LogType.SETTINGS) {
      setCol(settingsColumns)
    } else if (logType === LogType.CCEVENT) {
      setCol(ccColumns)
    } else {
      setCol(columns)
    }

    fetchLogs();

    return () => {
      if (listener.current) clearInterval(listener.current);
      if (timeout.current) clearInterval(timeout.current);
    };
  }, []);

  const fetchLogs = useCallback((newPage = 0) => {
    setLoading(true)

    let url = ''
    if (logType === LogType.DEBUG) {
      url = '/devices/new-debug/' + deviceData?._id + `/${pageSize}/${newPage}`
      fetchLogoutOn401(
        userCon,
        process.env.REACT_APP_BACKEND_URL + url,
        {
          headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
        },
      )
        .then((res) => {
          if (res.ok) return res.json();
          else {
            setLoading(false)
            throw Error;
          }
        })
        .then((json) => {
          if (json.totalData.length < 1) {
            return;
          }
          if (rowCount === 0 && json.totalCount[0].count) {
            setRowCount(json.totalCount[0].count)
          }
          setDeviceLog((json.totalData as LogDto).map((obj, i) => ({
            index: i,
            time: moment(obj.time),
            source: obj.source,
            type: obj.type,
            description: obj.description,
            tokenIssued: obj.tokenIssued,
            revokeCode: obj.revokeCode,
            tokenLifespan: obj.tokenLifespan,
            showChanges: obj.showChanges != null ? obj.showChanges.length.toString() : '',
          })))
        }

        ).then(() => {
          setLoading(false)
        });
    }
    else {
      if (logType === LogType.STATUS) {
        url = '/devices/errors/' + deviceData?._id
      } else {
        url = '/devices/logs/' + logType + '/' + deviceData?._id
      }
      fetchLogoutOn401(
        userCon,
        process.env.REACT_APP_BACKEND_URL + url,
        {
          headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
        },
      )
        .then((res) => {
          if (res.ok) return res.json();
          else {
            setLoading(false)
            throw Error;
          }
        })
        .then((json) =>
          setDeviceLog(
            (json as LogDto)
              .map((obj, i) => ({
                index: i,
                time: moment(obj.time),
                source: obj.source,
                type: obj.type,
                description: obj.description,
                tokenIssued: obj.tokenIssued,
                revokeCode: obj.revokeCode,
                tokenLifespan: obj.tokenLifespan,
                showChanges: obj.showChanges != null ? obj.showChanges.length.toString() : ''
              }))
              .reverse(),
          ),
        ).then(() => {
          setPageSize(deviceLog.length)
          setLoading(false)
        });
    }

  }, [deviceData?._id, deviceLog.length, logType, pageSize, rowCount, userCon]);

  return (
    <div className={classes.formRoot}>
      <AppBar
        color="secondary"
        className={classes.appBar}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">{deviceData?.deviceID} {LogTypeMap[logType!]} Log</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />

      <Paper style={{ height: '900px' }}>
        <Typography>
          {`Times in controller timezone: ${deviceData?.timeZone
            ? moment().tz(deviceData.timeZone)?.format('z')
            : 'Unknown'
            }`}
        </Typography>
        {logType === LogType.DEBUG ? <DataGrid
          loading={loading}
          className={classes.deviceLog}
          pageSize={pageSize}
          onPageSizeChange={(newSize) => setPageSize(newSize.pageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageChange={(newPage) => fetchLogs(newPage.page)}
          density="compact"
          rows={deviceLog}
          pagination={true}
          paginationMode='server'
          rowCount={rowCount}
          columns={col}
          getRowId={(row) => row.index}
          ref={(n) => {
            tableRef.current = n;
          }}
        /> : <DataGrid
          loading={loading}
          className={classes.deviceLog}
          pageSize={pageSize}
          onPageSizeChange={(newSize) => setPageSize(newSize.pageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          density="compact"
          rows={deviceLog}
          columns={col}
          getRowId={(row) => row.index}
          ref={(n) => {
            tableRef.current = n;
          }}
        />
        }
      </Paper>
    </div>
  );
}

export default DeviceLog;
