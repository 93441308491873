import {
  AppBar,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { MouseEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { trimShowFields } from '../configuration/ShowForm';
import {
  CustomerControlShowConfiguration,
  defaultCustomerControlShowConfiguration,
} from './CustomerControlShowLineup';
import CustomerControlShowForm from './CustomerControlShowForm';

type props = {
  closeDialog: () => void;
  showData: null | CustomerControlShowConfiguration;
  submitShow: (show: CustomerControlShowConfiguration) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      height: `68px`,
      borderBottom: '1px solid #e0e0e0',
    },
    formRoot: {
      minHeight: '100%',
      backgroundColor: '#F4F7FC',
    },
    // necessary for content to be below app bar
    toolbar: { height: '68px' },
    formContainer: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      maxWidth: `min(400px, calc(100% - ${theme.spacing(2) * 2}px))`,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  }),
);

export default function ShowDialog({
  closeDialog,
  showData,
  submitShow,
}: props) {
  const classes = useStyles();
  const history = useHistory();
  const { control, handleSubmit } = useForm<CustomerControlShowConfiguration>({
    defaultValues: showData ?? { ...defaultCustomerControlShowConfiguration },
  });
  const [outsideEvent, setOutsideEvent] = useState<null | MouseEvent>(null);
  const [blockSubmit, setBlockSubmit] = useState(false);

  useEffect(() => {
    // push current history so pop doesn't go back a page
    history.push(history.location);
    // close dialog on back button, since we pushed this goes back to the same page
    return history.listen((_, action) => {
      if (action === 'POP') {
        closeDialog();
      }
    });
  }, []);

  return (
    <div className={classes.formRoot} onClick={(e) => setOutsideEvent(e)}>
      <AppBar
        color="secondary"
        className={classes.appBar}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {showData ? 'Edit Show' : 'Add Show'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <div className={classes.formContainer}>
        <Paper>
          <form
            className={classes.form}
            onSubmit={(e) => {
              e.stopPropagation();
              if (blockSubmit) e.preventDefault();
              else
                handleSubmit((show) => {
                  trimShowFields(show);
                  submitShow(show);
                  closeDialog();
                })(e);
            }}
          >
            <CustomerControlShowForm<CustomerControlShowConfiguration>
              control={control}
              setBlockSubmit={setBlockSubmit}
              outsideEvent={outsideEvent}
              basePath=""
              showData={showData}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: 'auto' }}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
}
