import {
  FormControlLabel,
  makeStyles,
  Paper,
  Switch,
  Typography,
  Theme,
  createStyles,
} from '@material-ui/core';
import { useContext } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import {
  refreshUserContext,
  tUserContext,
  UserContext,
} from '../context/UserStateManager';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputSwitch: {
      justifyContent: 'start',
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    },
  }),
);

async function toggleAutoRegistration(enabled: boolean, userCon: tUserContext) {
  let res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org?._id}/registration`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        enableRegistration: enabled,
      }),
    },
  );
  if (res.ok) refreshUserContext(userCon.state, userCon.setState);
}

export default function OrgSecurity() {
  const userCon = useContext(UserContext);
  const classes = useStyles();

  return (
    <div>
      <Paper>
        <Typography variant="h3">Automatic Registration</Typography>
        <FormControlLabel
          label="Enable Automatic Registration"
          labelPlacement="start"
          className={classes.inputSwitch}
          control={
            <Switch
              checked={userCon.state.currentOrg?.org.enableRegistration}
              color="primary"
              onChange={(e) => {
                toggleAutoRegistration(e.target.checked, userCon);
              }}
            />
          }
        />
      </Paper>
    </div>
  );
}
