import { useContext, useEffect, useRef, useState } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import DeviceEventsList from '../configuration/DeviceEventsList';
import ShowLineupList from '../configuration/ShowLineupList';
import {
  refreshUserContext,
  tUserContext,
  UserContext,
} from '../context/UserStateManager';
import {
  EventSettings,
  ShowConfiguration,
} from '../devices/DeviceSettingsObject';

export interface OrgDefaultsDto {
  lineup: ShowConfiguration[];
  events: EventSettings[];
}

async function sendDefaults(userCon: tUserContext, defaults: OrgDefaultsDto) {
  return await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org?._id}/defaults`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify(defaults),
    },
  );
}

function OrgDefaults() {
  const userCon = useContext(UserContext);
  const loaded = useRef(false);
  const [defaults, setDefaults] = useState({
    lineup: userCon.state.currentOrg?.org.defaultLineup ?? [],
    events: userCon.state.currentOrg?.org.defaultEvents ?? [],
  });

  useEffect(() => {
    if (loaded.current) {
      sendDefaults(userCon, defaults).then((res) => {
        refreshUserContext(userCon.state, userCon.setState);
      });
    } else loaded.current = true;
  }, [defaults]);

  useEffect(() => {
    loaded.current = false;
    setDefaults({
      lineup: userCon.state.currentOrg?.org.defaultLineup ?? [],
      events: userCon.state.currentOrg?.org.defaultEvents ?? [],
    });
  }, [userCon.state.currentOrg]);


  return (
    <div>
      <DeviceEventsList
        events={defaults.events}
        setEvents={(events) => setDefaults({ ...defaults, events })}
        orgDefault
      />
      <br />
      <ShowLineupList
        showList={defaults.lineup}
        setShows={(lineup) => setDefaults({ ...defaults, lineup })}
        orgDefault
      />

    </div>
  );
}
export default OrgDefaults;
