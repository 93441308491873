import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Dialog,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useContext, useRef, useState } from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { tUserContext, UserContext } from '../context/UserStateManager';
import ConfirmationModal from '../common/ConfirmationModal';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { ShowConfiguration } from '../devices/DeviceSettingsObject';
import NewLineupModal from './NewLineupModal';
import { EditOutlined } from '@material-ui/icons';
import EditLineupForm from '../common/EditLineupForm';
import {LineupType} from "../Enums";

export interface ShowLineup {
  _id: string;
  name: string;
  lineup: any[];
}

export async function getSystemLineups(
  userCon: tUserContext,
): Promise<ShowLineup[]> {
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/show-lineup/system`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    },
  );
  if (resp.ok) {
    return resp.json();
  } else {
    throw new Error('Request unsuccessful');
  }
}

async function addSystemLineup(
  userCon: tUserContext,
  lineup: ShowConfiguration[],
  name?: string,
) {
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/show-lineup/system`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        name,
        lineup,
      }),
    },
  );
  if (resp.ok) {
    return resp.json();
  } else {
    if (resp.status === 400) {
      throw new Error('That name is already in use.');
    }
    throw new Error('Request unsuccessful');
  }
}

async function updateSystemLineup(userCon: tUserContext, lineup: ShowLineup) {
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/show-lineup/${lineup._id}`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        name: lineup.name,
        lineup: lineup.lineup,
      }),
    },
  );
  if (resp.ok) {
    return resp.json();
  } else {
    if (resp.status === 400) {
      throw new Error('That name is already in use.');
    }
    throw new Error('Request unsuccessful');
  }
}

async function deleteSystemLineup(userCon: tUserContext, lineupId: string) {
  if (!lineupId) return;
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/show-lineup/${lineupId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    },
  );
  if (!resp.ok) {
    throw new Error('Request unsucessful');
  }
}

const useStyles = makeStyles({
  newLineupButton: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
});

export default function SystemLineupList() {
  const styles = useStyles();
  const userCon = useContext(UserContext);
  const targetId = useRef('');
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const queryClient = useQueryClient();
  const systemLineups = useQuery('system-lineups', () =>
    getSystemLineups(userCon),
  );
  const createMutation = useMutation(
    ({ lineup, name }: { lineup: ShowConfiguration[]; name?: string }) =>
      addSystemLineup(userCon, lineup, name),
    {
      onSuccess: () => queryClient.invalidateQueries('system-lineups'),
    },
  );
  const updateMutation = useMutation(
    (lineup: ShowLineup) => updateSystemLineup(userCon, lineup),
    {
      onSuccess: () => queryClient.invalidateQueries('system-lineups'),
    },
  );
  const deleteMutation = useMutation(
    (id: string) => deleteSystemLineup(userCon, id),
    {
      onSuccess: () => queryClient.invalidateQueries('system-lineups'),
    },
  );

  return (
    <div>
      <Paper>
        <Typography variant="h3">System Lineups</Typography>
        <Typography>
          Lineups configured here will be available to load for all users.
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={1}></TableCell>
              <TableCell width={1}></TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(systemLineups.data ?? []).map(({ _id, name }) => (
              <TableRow key={_id}>
                <TableCell width={1}>
                  <IconButton
                    onClick={() => {
                      targetId.current = _id;
                      setEditFormOpen(true);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </TableCell>
                <TableCell width={1}>
                  <IconButton
                    onClick={() => {
                      targetId.current = _id;
                      setRemoveModalOpen(true);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={styles.newLineupButton}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setAddModalOpen(true)}
          >
            Add Lineup
          </Button>
        </div>
      </Paper>
      <NewLineupModal
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        onAccept={async (name: string) => {
          try {
            await createMutation.mutateAsync({ lineup: [], name });
            return {
              success: true,
              message: 'Your new lineup has been added.',
            };
          } catch (e: any) {
            return { success: false, message: e.message };
          }
        }}
      />
      <ConfirmationModal
        open={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        onConfirm={() =>
          deleteMutation.mutate(targetId.current, {
            onSettled: () => setRemoveModalOpen(false),
          })
        }
        title="Remove System Lineup"
        message="Are you sure you want to remove this lineup?"
        confirmText="Remove"
      />
      <Dialog
        fullScreen
        open={editFormOpen}
        onClose={() => setEditFormOpen(false)}
      >
        <EditLineupForm
          lineupType={LineupType.MOOD_LIGHTING}
          title="System"
          closeDialog={() => setEditFormOpen(false)}
          showLineup={
            systemLineups.data?.find(({ _id }) => _id === targetId.current) ||
            null
          }
          submit={async (lineup) => {
            try {
              await updateMutation.mutateAsync(lineup);
              return { success: true };
            } catch (e: any) {
              return { success: false, message: e.message };
            }
          }}
        />
      </Dialog>
    </div>
  );
}
