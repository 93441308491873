import { WHITE } from '../configuration/MultiColorPicker';
import {
  ConditionType,
  ControllerType, MusicShowName,
  ShowName,
  SignalLogicType,
  WhiteCeilingType,
} from '../Enums';

export interface InputCondition {
  input: number;
  logicType: SignalLogicType;
  duration: number;
}
export const defaultInputCondition: InputCondition = {
  input: 1,
  logicType: SignalLogicType.ACTIVE_HIGH,
  duration: 100,
};

export interface TimeConditionItem {
  startTime: string;
  endTime: string;
  daysOfWeek: boolean[];
}
export interface TimeCondition {
  times: TimeConditionItem[];
}
export const defaultTimeCondition: TimeCondition = {
  times: [],
};

export interface ConditionObject {
  conditionType: ConditionType;
  condition?: InputCondition | TimeCondition;
}

export interface SystemSettings {
  systemEnable: ConditionObject;
  dimming: {
    conditionType: ConditionType;
    condition?: InputCondition | TimeCondition;
    brightness: number;
  };
  moodShowEnable: ConditionObject;
  maintenanceMode: {
    conditionType: ConditionType;
    condition?: InputCondition | TimeCondition;
    brightness: number;
  };
  whiteCeilingBehavior: WhiteCeilingType;
  triggerCount: number;
  pixelMap: number[][];
  zones: { name: string; pixels: number[][] }[];
}




export interface MusicShowConfiguration {
  showName: MusicShowName;
  colors?: [number, number, number][];
  speed?: number;
  backward?: boolean;
  duration?: number;
}
export const defaultMusicShowConfiguration: MusicShowConfiguration = {
  showName: MusicShowName.JUMP,
  colors: [[...WHITE]],
  speed: 5,
  backward: false,
  duration: 30,
};




export interface ShowConfiguration {
  showName: ShowName;
  colors?: [number, number, number][];
  speed?: number;
  backward?: boolean;
  duration?: number;
}
export const defaultShowConfiguration: ShowConfiguration = {
  showName: ShowName.SOLID,
  colors: [[...WHITE]],
  speed: 5,
  backward: false,
  duration: 30,
};

export interface TriggerSettings {
  name: string;
  zone: number;
  condition: InputCondition;
  show: ShowConfiguration;
}
export const defaultTriggerSettings: TriggerSettings = {
  name: '',
  zone: 0,
  condition: { ...defaultInputCondition },
  show: defaultShowConfiguration,
};

export interface EventSettings {
  name: string;
  startDate: string;
  endDate: string;
  shows: ShowConfiguration[];
}

export interface MusicControl {
  showLineup: MusicShowConfiguration[];
  musicShowEnable: {
    conditionType: ConditionType;
    condition?: InputCondition | TimeCondition;
  };
  musicShowZone: number;
}

export interface CustomerControlSettings {
  active: boolean;
  enabledShows: string[];
  inputs: {
    start?: number;
    priority?: number[];
  };
}

export default interface DeviceSettings {
  controllerType: ControllerType;
  settings: SystemSettings;
  showLineup: ShowConfiguration[];
  moodLightingZone: number;
  triggers: TriggerSettings[];
  events: EventSettings[];
  musicControl: MusicControl;
  customerControl: CustomerControlSettings;
  ignoreLineupOverride?: boolean;
}
