import {
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import moment from 'moment';
import { useContext, useMemo, useState } from 'react';
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormReturn,
} from 'react-hook-form';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {
  ConditionObject,
  defaultInputCondition,
  defaultTimeCondition,
  TimeCondition,
  TimeConditionItem,
} from '../devices/DeviceSettingsObject';
import {
  ConditionType,
  ConditionTypeMap,
  SignalLogicType,
  SignalLogicTypeMap,
} from '../Enums';
import TimeSelectModal from './TimeSelectModal';
import { DeviceSettingsContext } from './DevicePage';
import { EditOutlined } from '@material-ui/icons';

type props<FormType extends FieldValues> = {
  formObject: UseFormReturn<FormType>;
  defaultVal: ConditionObject;
  basePath: string;
  trigger?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
);

export default function ConditionSelection<
  FormType extends Record<string, any>,
>({ formObject, defaultVal, basePath, trigger }: props<FormType>) {
  const classes = useStyles();
  const { configuration, usedInputs, updating } = useContext(DeviceSettingsContext);
  const [selectedType, setSelectedType] = useState(defaultVal.conditionType);
  const [modalOpen, setModalOpen] = useState(false);
  const inputs = useMemo(
    () => Array.from(Array(configuration.settings.triggerCount).keys(), x => x + 1),
    [configuration.settings.triggerCount],
  );
  const conditionPath = (basePath + 'condition') as Path<FormType>;
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number | null>(
    null,
  );

  return (
    <div>
      {!trigger && (
        <Controller
          name={(basePath + 'conditionType') as Path<FormType>}
          control={formObject.control}
          defaultValue={defaultVal.conditionType}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControlLabel
              label="Condition Type:"
              labelPlacement="start"
              control={
                <Select
                  labelId="condition-type-label"
                  value={value}
                  disabled={updating}
                  onChange={(e) => {
                    if (
                      (e.target.value as ConditionType) !==
                      defaultVal.conditionType
                    ) {
                      formObject.setValue(
                        conditionPath,
                        (e.target.value === ConditionType.TIME_CONDITION
                          ? defaultTimeCondition
                          : defaultInputCondition) as UnpackNestedValue<
                          PathValue<FormType, Path<FormType>>
                        >,
                      );
                    } else {
                      formObject.setValue(
                        conditionPath,
                        defaultVal.condition as UnpackNestedValue<
                          PathValue<FormType, Path<FormType>>
                        >,
                      );
                    }
                    setSelectedType(e.target.value as ConditionType);
                    onChange(e);
                  }}
                >
                  <MenuItem value={ConditionType.ALWAYS_OFF}>
                    {ConditionTypeMap[ConditionType.ALWAYS_OFF]}
                  </MenuItem>
                  <MenuItem value={ConditionType.ALWAYS_ON}>
                    {ConditionTypeMap[ConditionType.ALWAYS_ON]}
                  </MenuItem>
                  <MenuItem value={ConditionType.INPUT_CONDITION}>
                    {ConditionTypeMap[ConditionType.INPUT_CONDITION]}
                  </MenuItem>
                  <MenuItem value={ConditionType.TIME_CONDITION}>
                    {ConditionTypeMap[ConditionType.TIME_CONDITION]}
                  </MenuItem>
                </Select>
              }
            />
          )}
        />
      )}
      {(selectedType === ConditionType.INPUT_CONDITION ||
        selectedType === ConditionType.TIME_CONDITION) && (
        <Controller
          name={conditionPath}
          control={formObject.control}
          defaultValue={defaultVal.condition}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            if (selectedType === ConditionType.INPUT_CONDITION) {
              return (
                <div>
                  <FormControlLabel
                    label="Input Number:"
                    labelPlacement="start"
                    control={
                      <FormControl>
                        <Select
                          disabled={updating}
                          value={value.input}
                          onChange={(e) => {
                            formObject.clearErrors();
                            onChange({ ...value, input: e.target.value });
                          }}
                          error={'input' in (error ?? {})}
                        >
                          {inputs.map((val) => (
                            <MenuItem
                              key={val}
                              value={val}
                              disabled={
                                selectedType ===
                                  ConditionType.INPUT_CONDITION &&
                                usedInputs.indexOf(val) !== -1
                              }
                            >
                              {`Input ${val}`}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!error && (
                          <FormHelperText error>
                            {(error as any).input.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    }
                  />
                  <FormControlLabel
                    label="Logic Type:"
                    labelPlacement="start"
                    control={
                      <Select
                        disabled={updating}
                        value={value.logicType}
                        onChange={(e) => {
                          onChange({ ...value, logicType: e.target.value });
                        }}
                      >
                        {Object.keys(SignalLogicTypeMap)
                          .slice(0, trigger ? 4 : 2) // hide rising/falling edge if not in triggers
                          .map((key) => (
                            <MenuItem key={key} value={key}>
                              {SignalLogicTypeMap[key as SignalLogicType]}
                            </MenuItem>
                          ))}
                      </Select>
                    }
                  />
                  {trigger &&
                    (value.logicType === SignalLogicType.FALLING_EDGE ||
                      value.logicType === SignalLogicType.RISING_EDGE) && (
                      <FormControlLabel
                        label="Duration:"
                        labelPlacement="start"
                        control={
                          <FormControlLabel
                            label="s"
                            labelPlacement="end"
                            control={
                              <TextField
                                disabled={updating}
                                type="number"
                                value={value.duration + ''}
                                onChange={(e) => {
                                  onChange({
                                    ...value,
                                    duration: parseFloat(e.target.value),
                                  });
                                }}
                                // error={!!error}
                                error={!!error && error.type === 'duration'}
                                // helperText={error ? error.message : null}
                                helperText={
                                  !!error && error.type === 'duration'
                                    ? error.message
                                    : null
                                }
                                InputProps={{
                                  inputProps: { min: 1, max: 4000, step: 0.1 },
                                }}
                              />
                            }
                          />
                        }
                      />
                    )}
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell padding="none">Days</TableCell>
                        <TableCell padding="none">On Time</TableCell>
                        <TableCell padding="none">Off Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(value as TimeCondition).times.map((val, index) => (
                        <TableRow key={index}>
                          <TableCell padding="none" width={1}>
                            <IconButton
                              disabled={updating}
                              style={{ padding: '0' }}
                              onClick={() => {
                                setSelectedTimeIndex(index);
                                setModalOpen(true);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                          </TableCell>
                          <TableCell padding="none" width={1}>
                            <IconButton
                              disabled={updating}
                              style={{ padding: '0' }}
                              onClick={() => {
                                onChange({
                                  times: (value as TimeCondition).times.filter(
                                    (_: any, i: number) => i !== index,
                                  ),
                                });
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: '0', paddingRight: '8px' }}
                            width={130}
                          >
                            {[
                              'Sunday',
                              'Monday',
                              'Tuesday',
                              'Wednesday',
                              'Thursday',
                              'Friday',
                              'Saturday',
                            ]
                              .filter((_, i) => val.daysOfWeek[i])
                              .map((s) => s.slice(0, 3))
                              .join(', ')}
                          </TableCell>
                          <TableCell padding="none" width={100}>
                            {moment(val.startTime, 'HHmm').format('h:mm a')}
                          </TableCell>
                          <TableCell padding="none" width={100}>
                            {moment(val.endTime, 'HHmm').format('h:mm a')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {(value as TimeCondition).times.reduce(
                    (counter, time) =>
                      counter +
                      time.daysOfWeek.reduce((c, b) => (b ? c + 1 : c), 0),
                    0,
                  ) < 7 && (
                    <Button
                      disabled={updating}
                      className={classes.button}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      Add Rule
                    </Button>
                  )}
                  <TimeSelectModal
                    open={modalOpen}
                    onClose={() => {
                      setSelectedTimeIndex(null);
                      setModalOpen(false);
                    }}
                    onSubmit={(val: any) => {
                      const times = [...value.times];
                      if (selectedTimeIndex !== null) {
                        times[selectedTimeIndex] = val;
                      } else {
                        times.push(val);
                      }
                      onChange({
                        times,
                      });
                      setModalOpen(false);
                      setSelectedTimeIndex(null);
                    }}
                    disabled={value.times
                      .filter((_: any, i: number) => i !== selectedTimeIndex)
                      .reduce(
                        (a: boolean[], c: TimeConditionItem) =>
                          c.daysOfWeek.map((d, i) => a[i] || d),
                        Array(7).fill(false),
                      )}
                    current={
                      selectedTimeIndex !== null
                        ? value.times[selectedTimeIndex]
                        : undefined
                    }
                  />
                </div>
              );
            }
          }}
          rules={{
            validate: {
              duration: (condition) => {
                if ('duration' in condition) {
                  if (isNaN(condition.duration)) {
                    return 'Required';
                  } else if ((condition.duration as number) < 1) {
                    return 'Must be greater than 1s';
                  }
                }
              },
            },
          }}
        />
      )}
    </div>
  );
}
