import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { UserContext } from '../context/UserStateManager';
import { ControllerTypeMap, ShowNameMap } from '../Enums';
import { DeviceModel, DeviceStatus } from './Devices';

const useStyles = makeStyles({
  DeviceList: {
    marginTop: '8px',
    paddingRight: '8px',
    height: `calc(99vh - 152px)`,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  DevicePaper: {
    margin: '8px 0',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  DeviceInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldName: {
    marginRight: '4px',
  },
  fieldValue: {
    fontWeight: 300,
  },
  StatusBubble: {
    height: '20px',
    width: '20px',
    borderRadius: '20px',
  },
  StatusGood: {
    backgroundColor: '#11E300',
  },
  StatusBad: {
    backgroundColor: '#bebebe',
  },
});

export function deviceIsConnected(lastCheckin: Date) {
  return moment(lastCheckin).isAfter(moment().subtract(5, 'minutes'));
}

export function DeviceList({ devices }: { devices: DeviceModel[] }) {
  const classes = useStyles();
  const history = useHistory();
  const userCon = useContext(UserContext);
  const [deviceStatuses, setDeviceStatuses] = useState<(DeviceStatus | null)[]>(
    [],
  );

  useEffect(() => {
    const controller = new AbortController();
    (async function () {
      Promise.all(
        devices.map((device) =>
          fetchLogoutOn401(
            userCon,
            process.env.REACT_APP_BACKEND_URL + '/devices/status/' + device._id,
            {
              headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
              signal: controller.signal
            },
          ),
        ),
      ).then((results) => {
        return Promise.all(
          results
            .filter((res) => !!res)
            .map((res) => (res && res.ok ? res.json() : null)),
        );
      }).then((data) => {
        setDeviceStatuses(data);
      }).catch(e => { });
    })();

    return () => {
      controller.abort();
    }
  }, [devices, userCon.state.jwtToken]);

  return (
    <div>
      <Typography variant="h2">Controllers</Typography>
      <Grid container spacing={3}>
        {devices.map((device, i) => (
          <Grid item xs={12} md={12} key={i}>
            <Paper
              className={classes.DevicePaper}
              key={device._id}
              onClick={() => {
                userCon.setState({ ...userCon.state, currentDevice: device });
                history.push('/controller/' + device._id);
              }}
            >
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={12} md={5}>
                  <div className={classes.DeviceInfo}>
                    <Typography className={classes.fieldName} variant="h3">
                      Name:
                    </Typography>
                    <Typography className={classes.fieldValue} variant="h3">
                      {device.name}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className={classes.DeviceInfo}>
                    <Typography className={classes.fieldName} variant="h3">
                      Location:
                    </Typography>
                    <Typography className={classes.fieldValue} variant="h3">
                      {device.location}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className={classes.DeviceInfo}>
                    <Typography className={classes.fieldName} variant="h3">
                      Connected:
                    </Typography>
                    <div
                      className={
                        classes.StatusBubble +
                        ' ' +
                        (deviceStatuses[i]
                          ? classes.StatusGood
                          : classes.StatusBad)
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className={classes.DeviceInfo}>
                    <Typography className={classes.fieldName} variant="h3">
                      Last Checkin Time:
                    </Typography>
                    {device && device.lastCheckin && device.timeZone && (
                      <Typography className={classes.fieldValue} variant="h3">
                        {moment(device.lastCheckin)
                          .tz(device.timeZone)
                          .format('hh:mm:ssa z')}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className={classes.DeviceInfo}>
                    <Typography className={classes.fieldName} variant="h3">
                      Controller ID:
                    </Typography>
                    <Typography className={classes.fieldValue} variant="h3">
                      {device.deviceID}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className={classes.DeviceInfo}>
                    <Typography className={classes.fieldName} variant="h3">
                      Device Type:
                    </Typography>
                    <Typography className={classes.fieldValue} variant="h3">
                      {device.configuration.controllerType ? ControllerTypeMap[device.configuration.controllerType] : ControllerTypeMap["DEFAULT_TYPE"]}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );

}

export default DeviceList;
