import {
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';

interface TabPanelProps {
  title: string;
  content: { title: string; component: React.ReactNode }[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      fontSize: '16px',
      fontWeight: 800,
    },
    tabContainer: {
      [theme.breakpoints.up('md')]: {
        width: 'calc(99vw - 295px - 48px)',
      },
      marginTop: theme.spacing(1),
    },
    TabScrollButtons: {
      '&.Mui-disabled': {
        opacity: 0.3,
      },
    },
  }),
);

function TabNavigation(props: TabPanelProps) {
  const { title, content, ...other } = props;
  const classes = useStyles();
  const [tabNum, setTabNum] = useState(0);

  return (
    <div {...other}>
      <Typography variant="h2">{title}</Typography>
      <Tabs
        className={classes.tabContainer}
        classes={{ scrollButtons: classes.TabScrollButtons }}
        value={tabNum}
        onChange={(e, n) => setTabNum(n)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        aria-label="tab navigation"
      >
        {content.map((t, i) => (
          <Tab
            id={`scrollable-auto-tab-${i}`}
            className={classes.tab}
            label={t.title}
            key={t.title + i}
            aria-controls={`scrollable-auto-tabpanel-${i}`}
          />
        ))}
      </Tabs>
      {content.map((t, i) => (
        <div
          role="tabpanel"
          hidden={tabNum !== i}
          key={'content' + t.title + i}
          id={`scrollable-auto-tabpanel-${i}`}
          aria-labelledby={`scrollable-auto-tab-${i}`}
        >
          {tabNum === i && t.component}
        </div>
      ))}
    </div>
  );
}
export default TabNavigation;
