import { createContext, ReactNode, useEffect, useState } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { DeviceModel } from '../devices/Devices';
import { OrgRole, SysRole } from '../Enums';
import { OrgModel } from '../orgs/Orgs';
import { UserModel } from '../users/Users';

export type UserState = {
  jwtToken: string;
  user: UserModel;
  currentOrg: null | { org: OrgModel; role: OrgRole };
  currentDevice: null | DeviceModel;
};

export type tUserContext = {
  state: UserState;
  setState: (state: UserState) => void;
};

const init = {
  state: {
    jwtToken: '',
    user: {
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
      role: SysRole.SYSTEM_USER,
      orgs: [],
      locked: false,
      hasAcceptedInvite: false,
    },
    currentOrg: null,
    currentDevice: null,
  },
  setState: () => true,
};
export const UserContext = createContext<tUserContext>(init);

export const setNoAuth = (userCon: tUserContext) => {
  window.localStorage.removeItem('userContext');
  userCon.setState({
    ...userCon.state,
    jwtToken: '',
  });
};

export const getOrgsForAdmin = async (
  userCon: tUserContext,
  orgId?: string,
) => {
  const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/orgs', {
    headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
  });
  let orgs;
  if (res.ok) {
    orgs = await res.json();
  } else return;
  // set role as admin for Sys Admins/Managers
  orgs = orgs.map((org: OrgModel) => ({
    org,
    devices: [],
    role: OrgRole.ORG_ADMIN,
  }));
  // update currentOrg
  let currentOrg = null;
  if (orgs.length > 0) {
    // use passed orgId as currentOrg, if not then old current org, if not then default to first available org
    const targetOrg =
      orgId ?? userCon.state.currentOrg?.org?._id ?? orgs[0].org._id;
    currentOrg = {
      org: await fetchOrgInfo(userCon, targetOrg),
      role: OrgRole.ORG_ADMIN,
    };
  }
  userCon.setState({
    ...userCon.state,
    user: { ...userCon.state.user, orgs: orgs },
    currentOrg,
  });
};

export async function fetchOrgInfo(userCon: tUserContext, orgId: string) {
  const res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL + '/orgs/' + orgId,
    {
      headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
    },
  );
  return res.ok ? await res.json() : null;
}

export function refreshUserContext(
  state: UserState,
  setState: (state: UserState) => void,
  orgId?: string,
) {
  if(!state.jwtToken) {
    window.localStorage.removeItem('userContext');
    setState({ ...init.state, jwtToken: '' });
    return;
  }
  try {
    fetch(process.env.REACT_APP_BACKEND_URL + '/users/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + state.jwtToken,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error;
      })
      .then((user: UserModel) => {
        if (!user) return;
        const newState: UserState = {
          ...state,
          user,
          jwtToken: state.jwtToken,
        };
        if (user.role !== SysRole.SYSTEM_USER) {
          getOrgsForAdmin({ state: newState, setState }, orgId);
        } else {
          // update currentOrg from user response
          const targetOrg =
            user.orgs.find((ele) => ele.org._id === orgId) ??
            user.orgs.find((ele) => ele.org._id === state.currentOrg?.org?._id) ??
            user.orgs[0];
          if (targetOrg) {
            fetchOrgInfo({ state, setState }, targetOrg.org._id).then((org) => {
              newState.currentOrg = { role: targetOrg.role, org };
              setState(newState);
            });
          } else {
            newState.currentOrg = null;
            setState(newState);
          }
        }
      })
      .catch(() => {
        window.localStorage.removeItem('userContext');
        setState({ ...init.state, jwtToken: '' });
      });
  } catch (e) {
    window.localStorage.removeItem('userContext');
    setState({ ...init.state, jwtToken: '' });
  }
}

export const UserStateManager = ({ children }: { children: ReactNode }) => {
  const storedState = window.localStorage.getItem('userContext');

  const [state, setState] = useState(
    storedState ? { ...JSON.parse(storedState) } : init.state,
  );

  useEffect(() => {
    if (storedState) {
      const parsed = JSON.parse(storedState) as UserState;
      refreshUserContext(parsed, setState);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('userContext', JSON.stringify(state));
  }, [state]);

  return (
    <UserContext.Provider value={{ state, setState }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserStateManager;
