import {
  Button,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';

type props = {
  open: boolean;
  onClose: Function;
  onConfirm: Function;
  title: string;
  message: string;
  confirmText: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      margin: theme.spacing(2),
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '20vh auto',
      width: 'fit-content',
      gap: '10px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
    },
  }),
);

export default function ConfirmationModal({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
}: props) {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={() => onClose()}>
      <Paper className={classes.paper}>
        <Typography variant="h2">{title}</Typography>
        <Typography>{message}</Typography>
        <div className={classes.buttons}>
          <Button
            variant="text"
            color="secondary"
            style={{ marginRight: '16px', color: '#818387' }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#F0142F', color: '#ffffff' }}
            onClick={() => onConfirm()}
          >
            {confirmText}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
