import {
  AppBar,
  Button,
  createStyles,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { EventSettings } from '../devices/DeviceSettingsObject';
import ShowLineupList from './ShowLineupList';

type props = {
  closeDialog: () => void;
  eventData: null | EventSettings;
  submitEvent: (event: EventSettings) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      height: `68px`,
      borderBottom: '1px solid #e0e0e0',
    },
    formRoot: {
      minHeight: '100%',
      backgroundColor: '#F4F7FC',
    },
    // necessary for content to be below app bar
    toolbar: { height: '68px' },
    formContainer: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      maxWidth: `min(500px, calc(100% - ${theme.spacing(2) * 2}px))`,
    },
    formPaper: {
      marginBottom: theme.spacing(2),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    inline: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
);



export default function EventDialogForm({
  closeDialog,
  eventData,
  submitEvent,
}: props) {
  const classes = useStyles();
  const { control, handleSubmit, getValues } = useForm<EventSettings>({
    defaultValues: eventData
      ? {
        ...eventData,
        startDate: moment(eventData.startDate + '/' + moment().year()).format('MM/DD/YYYY'),
        endDate: moment(eventData.endDate + '/' + moment().year()).format('MM/DD/YYYY'),
      }
      : {
        name: '',
        startDate: moment().format('MM/DD/YYYY'),
        endDate: moment().format('MM/DD/YYYY'),
        shows: [],
      },
  });

  const validateDateRange = () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate")

    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      if (startDateObj > endDateObj) {
        return false
      }
      return true
    }
  };

  return (
    <div className={classes.formRoot}>
      <AppBar
        color="secondary"
        className={classes.appBar}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => {
              closeDialog();
            }}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {eventData ? 'Edit Event' : 'Add Event'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <div className={classes.formContainer}>
        <form
          className={classes.form}
          onSubmit={handleSubmit((event) => {
            submitEvent({
              ...event,
              startDate: moment(event.startDate).format('MM/DD'),
              endDate: moment(event.endDate).format('MM/DD'),
            });
            closeDialog();
          })}
        >
          <Paper className={classes.formPaper}>
            <Typography variant="h2">Event Information</Typography>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Name:"
                  labelPlacement="start"
                  style={{ maxWidth: '230px' }}
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'An event name is required',
                maxLength: {
                  value: 50,
                  message: 'Must be less than 50 characters',
                },
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Controller
                name="startDate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="Start Date"
                    disableToolbar
                    openTo="month"
                    views={['month', 'date']}
                    value={value}
                    onChange={(e) => {
                      onChange(e?.format('MM/DD/YYYY'))
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                rules={{ validate: () => validateDateRange() || 'Start Date is after End Date' }}
              />
              <div style={{ width: '30px' }} />
              <Controller
                name="endDate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="End Date"
                    disableToolbar
                    openTo="month"
                    views={['month', 'date']}
                    value={value}
                    onChange={(e) => {
                      onChange(e?.format('MM/DD/YYYY'))
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                rules={{ validate: () => validateDateRange() || 'End Date is before Start Date' }}
              />
            </div>
          </Paper>
          <Controller
            name="shows"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <ShowLineupList
                  showList={value}
                  setShows={onChange}
                  eventDialog
                />
                {!!error && (
                  <Typography
                    style={{
                      fontSize: '16px',
                      textAlign: 'center',
                      marginTop: '16px',
                    }}
                    variant="caption"
                    color="error"
                  >
                    {error.message}
                  </Typography>
                )}
              </>
            )}
            rules={{
              validate: {
                isAfter: (shows) => shows.length > 0 || "At least one show must be selected",
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            Submit Event
          </Button>
        </form>
      </div>
    </div>
  );
}
