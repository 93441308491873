import {
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import { useState, useContext } from 'react';
import { EventSettings } from '../devices/DeviceSettingsObject';
import { DeviceSettingsContext } from './DevicePage';
import EventDialogForm from './EventDialogForm';

type props = {
  events: EventSettings[];
  setEvents: (e: EventSettings[]) => void;
  orgDefault?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Content: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  }),
);

const EVENT_NUMBER_LIMIT = 365;

export default function DeviceEventsList({
  events,
  setEvents,
  orgDefault,
}: props) {
  const classes = useStyles();
  const { updating } = useContext(DeviceSettingsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogState, setDialogState] = useState({
    open: false,
    eventData: null as null | EventSettings,
    row: -1,
  });
  const closeDialog = () =>
    setDialogState({ open: false, eventData: null, row: -1 });

  events.sort((first, second) => {
    const startDateComparison = first.startDate.localeCompare(second.startDate);
    if (startDateComparison === 0) {
      return first.endDate.localeCompare(second.endDate);
    }
    return startDateComparison;
  });


  return (
    <div>
      <Paper className={classes.Content}>
        <Typography variant="h3" style={{ marginBottom: '8px' }}>
          {orgDefault && 'Default '}Event Schedule
        </Typography>
        {!orgDefault && (
          <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>
            Add events, which replace mood lighting for the date range, or leave
            this empty to use organization default events.
          </Typography>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, i) => (
              <TableRow key={i}>
                <TableCell width={1}>
                  <IconButton
                    disabled={updating}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                      setDialogState({
                        ...dialogState,
                        eventData: event,
                        row: i,
                      });
                    }}
                    style={{ padding: 0 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{event.name}</TableCell>
                <TableCell>
                  {moment(event.startDate, 'MM/DD').format('MMMM Do')}
                </TableCell>
                <TableCell>
                  {moment(event.endDate, 'MM/DD').format('MMMM Do')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setDialogState({ ...dialogState, open: true });
            }}
          >
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem
            color="error"
            onClick={() => {
              setAnchorEl(null);
              setEvents(events.filter((_, i) => i !== dialogState.row));
            }}
          >
            <Typography color="error">Delete</Typography>
          </MenuItem>
        </Menu>
        {events.length < EVENT_NUMBER_LIMIT && (
          <Button
            disabled={updating}
            variant="contained"
            color="primary"
            style={{ margin: '32px auto' }}
            onClick={() =>
              setDialogState({ open: true, eventData: null, row: -1 })
            }
          >
            Add an Event
          </Button>
        )}
        <Dialog fullScreen open={dialogState.open} onClose={closeDialog}>
          <EventDialogForm
            closeDialog={closeDialog}
            eventData={dialogState.eventData}
            submitEvent={(event) => {
              if (dialogState.eventData) {
                setEvents(
                  events.map((oldEvent, index) =>
                    dialogState.row === index ? event : oldEvent,
                  ),
                );
              } else {
                setEvents([...events, event]);
              }
            }}
          />
        </Dialog>
      </Paper>
    </div>
  );
}
