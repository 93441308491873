import {
  createStyles,
  Divider,
  FormControlLabel,
  makeStyles,
  Paper,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useContext } from 'react';
import { UserContext } from '../context/UserStateManager';
import { ShowConfiguration } from '../devices/DeviceSettingsObject';
import { DeviceSettingsContext } from './DevicePage';
import ShowLineupList from './ShowLineupList';
import OrgLineupOverrideBanner from "./OrgLineupOverrideBanner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: '8px',
      backgroundColor: '#707070',
    },
    inputSwitch: {
      justifyContent: 'center',
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    },
  }),
);

export function MoodLighting({
  setConfiguration,
}: {
  setConfiguration: Function;
}) {
  const classes = useStyles();
  const userCon = useContext(UserContext);
  const { configuration, updating } = useContext(DeviceSettingsContext);

  const overrideId = userCon.state.currentOrg?.org.lineupOverride || null

  return (
    <div>
      <OrgLineupOverrideBanner
        showBanner={
          !!userCon.state.currentOrg?.org.lineupOverride &&
          !configuration.ignoreLineupOverride
        }
        lineup={
          userCon.state.currentOrg?.org.orgLineups?.find(x => x._id === overrideId)?.name || null
        }
      />
      <ShowLineupList
        showList={configuration.showLineup}
        setShows={(showLineup: ShowConfiguration[]) =>
          setConfiguration({ ...configuration, showLineup })
        }
        zone={configuration.moodLightingZone ?? 0}
        setZone={(zoneNum) =>
          setConfiguration({ ...configuration, moodLightingZone: zoneNum })
        }
        disabled={
          (!!userCon.state.currentOrg?.org.lineupOverride &&
          !configuration.ignoreLineupOverride) || updating
        }
      />
      <Paper style={{ marginBottom: '10px' }}>
        <Typography variant="h3">Organization Lineup Sync</Typography>
        <Typography variant="h4">
          Organization managers may choose to sync mood lighting lineups for
          all Devices. If necessary, the sync may be ignored.
        </Typography>
        <Divider classes={{ root: classes.divider }} />
        <FormControlLabel
          label="Ignore Sync"
          labelPlacement="start"
          className={classes.inputSwitch}
          control={
            <Switch
              disabled={updating}
              checked={configuration.ignoreLineupOverride}
              color="primary"
              onChange={(e: any) => {
                setConfiguration({
                  ...configuration,
                  ignoreLineupOverride: e.target.checked,
                });
              }}
            />
          }
        />
        {userCon.state.currentOrg?.org.lineupOverride && (
          <Typography>
            <InfoOutlined style={{ marginRight: '10px' }} />
            There is currently an Organization Lineup Sync active. Settings
            will remain disabled until the sync is removed or ignored.
          </Typography>
        )}
      </Paper>
    </div>
  );
}
