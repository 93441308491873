import {
  makeStyles,
  List,
  Theme,
  createStyles,
  Divider,
  ListItemText,
} from '@material-ui/core';
import ListItemLink from './ListItemLink';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import UserProfileMenu from './UserProfileMenu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { useContext } from 'react';
import {
  setNoAuth,
  tUserContext,
  UserContext,
} from '../context/UserStateManager';
import { ListItem } from '@material-ui/core';
import OrganizationSelection from './OrganizationSelection';
import { OrgRole, SysRole } from '../Enums';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      flexDirection: `column`,
      height: '100%',
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `white`,
    },
    divider: {
      height: '2px',
      backgroundColor: '#707070',
    },
  }),
);

const navLinks = [
  {
    title: `Controllers`,
    path: `/controller`,
    icon: AssignmentOutlinedIcon,
    condition: () => true,
  },
  {
    title: 'Register Controller',
    path: '/register',
    icon: AddOutlinedIcon,
    condition: () => true, 
  },
  {
    title: `Organization Settings`,
    path: `/organization`,
    icon: SettingsOutlinedIcon,
    condition: (userCon: tUserContext) =>
      userCon.state.user.role !== SysRole.SYSTEM_USER ||
      userCon.state.currentOrg?.role === OrgRole.ORG_ADMIN,
  },
  {
    title: `Profile`,
    path: `/user`,
    icon: AccountCircleOutlinedIcon,
    condition: () => true,
  },
];

function DrawerContent({ closeDrawer }: { closeDrawer?: Function }) {
  const userCon = useContext(UserContext);
  const classes = useStyles();

  return (
    <div style={{ padding: '0 8px', height: '100%' }}>
      <List
        component="nav"
        aria-labelledby="main navigation"
        className={classes.navDisplayFlex}
      >
        <ListItem>
          <UserProfileMenu />
        </ListItem>
        <ListItem button>
          <OrganizationSelection />
        </ListItem>
        {navLinks.map(
          ({ title, path, icon, condition }) =>
            condition(userCon) && (
              <div
                key={title}
                onClick={() => {
                  if (closeDrawer) closeDrawer();
                  userCon.setState({
                    ...userCon.state,
                    currentDevice: null,
                  });
                }}
              >
                <ListItemLink primary={title} to={path} icon={icon} />
              </div>
            ),
        )}
        {userCon.state.user.role !== SysRole.SYSTEM_USER && (
          <div
            onClick={() => {
              if (closeDrawer) closeDrawer();
            }}
          >
            <Divider classes={{ root: classes.divider }} />
            <ListItemLink
              key="admin"
              primary="Admin Settings"
              to="/admin"
              icon={SupervisorAccountOutlinedIcon}
            />
          </div>
        )}
        <ListItem style={{ flexGrow: 1 }} />
        <ListItem
          button
          onClick={() => setNoAuth(userCon)}
          style={{ marginBottom: '32px' }}
        >
          <ExitToAppIcon color="error" />
          <div style={{ width: '16px' }} />
          <ListItemText
            primaryTypographyProps={{ color: 'error' }}
            primary="Logout"
          />
        </ListItem>
      </List>
    </div>
  );
}

export default DrawerContent;
