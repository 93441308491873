import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { MusicShowConfiguration } from '../devices/DeviceSettingsObject';
import { MusicShowConfigMap, MusicShowName, MusicShowNameMap } from '../Enums';
import MultiColorPicker, { WHITE } from './MultiColorPicker';

type props<ControlType extends FieldValues> = {
  control: Control<ControlType>;
  setBlockSubmit: (arg0: boolean) => void;
  outsideEvent: null | any;
  showData: null | MusicShowConfiguration;
  basePath: string;
  noDuration?: boolean;
};

export function trimShowFields(
  show: MusicShowConfiguration,
  removeDuration?: boolean,
) {
  if (MusicShowConfigMap[show.showName].maxColors === 0) delete show.colors;
  if (!MusicShowConfigMap[show.showName].direction) delete show.backward;
  if (!MusicShowConfigMap[show.showName].speed) delete show.speed;
  if (removeDuration) delete show.duration;
  else show.duration = parseFloat(show.duration as unknown as string);
}

export default function MusicShowForm<ControlType extends FieldValues>({
  control,
  showData,
  setBlockSubmit,
  outsideEvent,
  basePath,
  noDuration,
}: props<ControlType>) {
  const [selectedShow, setSelectedShow] = useState(
    showData?.showName ?? MusicShowName.JUMP,
  );
  const [duration, setDuration] = useState(
    showData?.duration
      ? [
          Math.floor(showData.duration) + '',
          Math.round(60 * (showData.duration % 1)) + '',
        ]
      : ['', ''],
  );

  return (
    <div>
      <Controller
        name={(basePath + 'showName') as Path<ControlType>}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            label="Show Type:"
            labelPlacement="start"
            control={
              <Select
                value={value}
                onChange={(e) => {
                  setSelectedShow(e.target.value as MusicShowName);
                  onChange(e);
                }}
              >
                {Object.keys(MusicShowNameMap).map((val) => (
                  <MenuItem key={val} value={val}>
                    {MusicShowNameMap[val as MusicShowName]}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        )}
      />

      {MusicShowConfigMap[selectedShow].maxColors > 0 && (
        <Controller
          name={(basePath + 'colors') as Path<ControlType>}
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiColorPicker
              minColors={MusicShowConfigMap[selectedShow].minColors}
              maxColors={MusicShowConfigMap[selectedShow].maxColors}
              value={(value ?? [...WHITE]) as [number, number, number][]}
              onChange={onChange}
              blockSubmit={setBlockSubmit}
              outsideEvent={outsideEvent}
            />
          )}
        />
      )}
      {MusicShowConfigMap[selectedShow].speed && (
        <Controller
          name={(basePath + 'speed') as Path<ControlType>}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControlLabel
              label="Speed:"
              labelPlacement="start"
              control={
                <Select value={value} onChange={onChange}>
                  {Array(10)
                    .fill(1)
                    .map((_, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1 + ''}
                      </MenuItem>
                    ))}
                </Select>
              }
            />
          )}
          rules={{
            validate: (value) => !isNaN(parseInt(value)) || 'Speed Required',
          }}
        />
      )}
      {MusicShowConfigMap[selectedShow].speed && <div style={{ width: '32px' }} />}
      {!noDuration && (
        <Controller
          name={(basePath + 'duration') as Path<ControlType>}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControlLabel
              label="Run Time (mm:ss):"
              labelPlacement="start"
              control={
                <div>
                  <TextField
                    type="number"
                    value={duration[0]}
                    onChange={(e) => {
                      const t = [e.target.value, duration[1]];
                      setDuration(t);
                      e.target.value =
                        parseInt(t[0]) + parseInt(t[1]) / 60 + '';
                      onChange(e);
                    }}
                    error={!!error}
                    helperText={
                      error &&
                      error.type === 'required' &&
                      isNaN(parseInt(duration[0]))
                        ? error.message
                        : null
                    }
                    InputProps={{
                      inputProps: { min: 0, max: 1440 },
                    }}
                  />
                  <span
                    style={{
                      margin: '4px',
                      lineHeight: '2',
                    }}
                  >
                    :
                  </span>
                  <TextField
                    type="number"
                    value={duration[1]}
                    onChange={(e) => {
                      const t = [duration[0], e.target.value];
                      setDuration(t);
                      e.target.value =
                        parseInt(t[0]) + parseInt(t[1]) / 60 + '';
                      onChange(e);
                    }}
                    error={!!error}
                    helperText={
                      error &&
                      (isNaN(parseInt(duration[1])) ||
                        error.type !== 'required')
                        ? error.message
                        : null
                    }
                    InputProps={{
                      inputProps: { min: 0, max: 55, step: 5 },
                    }}
                  />
                </div>
              }
            />
          )}
          rules={{
            required: 'Run Time Required',
            validate: (value) => {
              if (isNaN(parseFloat(value))) return 'Run time must be a number';
              if (parseFloat(value) < 5 / 60)
                return 'Minimum duration is 5 seconds.';
            },
          }}
        />
      )}
      {MusicShowConfigMap[selectedShow].direction && (
        <Controller
          name={(basePath + 'backward') as Path<ControlType>}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControlLabel
              label="Backwards"
              style={{ display: 'initial' }}
              control={
                <Checkbox
                  checked={value as boolean}
                  value={value}
                  color="primary"
                  onChange={onChange}
                />
              }
            />
          )}
        />
      )}
    </div>
  );
}
